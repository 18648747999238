import React, { Component } from 'react';
import { getLoggedInUser } from '../Services/GraphService';
import './History.css';
import AboutBanner from '../images/AboutBanner01.png';
import * as moment from 'moment';

export class History extends Component {

    constructor(props) {
        super(props);
        var gUser = getLoggedInUser();
        if (gUser === null)
            props.history.push('/');
        this.state = {

            historyData:[]
       
        };
        let emp = localStorage.getItem('emp');
        emp = JSON.parse(emp);
        (async () => fetch('api/Staff/GetHistoryByEmail?email=' + emp.login_addr)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    historyData:data
                });
            }))();

        console.log(this.state.historyData)
    }

    render() {
        const listItems = this.state.historyData.map((data) =>
            <div className="pad-35" key={data.toString()}>
                <p>Updated By <b>{data.modified_by}</b> On <b>{moment(data.modified_date).format("YYYY-MM-DD hh:mm:ss")}</b></p>
                <p>Deployment Start Date : <b>{data.startDate == null ? '' : moment(data.startDate).format("YYYY-MM-DD")}</b></p>
                <p>Deployment End Date   : <b>{data.startDate == null ? '' : moment(data.endDate).format("YYYY-MM-DD")}</b> </p>
                <p>Covid 19 Cell         : <b>{data.covidDeployed.replaceAll(',', ', ')}</b> </p>
                <p>Clinical Experience   : <b>{data.clinicalExp}</b> </p>
                <p>Clinical Type  : <b>{data.typeOfClinical.replaceAll(',', ', ')}</b> </p>
                <hr />
            </div>
        );
        return (<div className="mainFrame">
            <div className="heading">
                <h3>Deployment Status History</h3>
            </div>
            <div>
                {listItems}
            </div>
        </div>)
    }
}