import React, { createRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import './Filter.css';
import Select from 'react-select';
const components = {
    DropdownIndicator: null,
};

const customStyles = {
    control: base => ({
        ...base,
        height: 32,
        minHeight: 32,
        overflowY: 'scroll',
    }),
    input: () => ({
        height: 25,
        minHeight: 25,
    }),

};

export class Filter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Projects: '',
            Roles: '',
            Skills: '',
            inputValue: '',
            skillOptions: [],
            direct:''
        };
        this.inputProjects = createRef();
        this.inputRoles = createRef();
        this.inputdirect = createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.advanceSearchButton = this.props.advanceSearchButton;
        this.clearSearch = this.clearSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        (async () => fetch('api/Staff/GetStaffMaster')
            .then(response => response.json())
            .then(data => {
                this.setState({ skillOptions: data.masterSkills, optionsDb: data.masterSkills, directOptions: data.allStaff_Directory });
            }))();
    }

    handleOnChange = v => {
        this.setState({ direct: v });
    };


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleInputChangeSearch = (inputValue) => {
        this.setState({ inputValue });
    };

    handleChange = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
        }
        this.setState({ Skills: list.substring(0, list.length - 1) });
    };

    clearSearch = () => {
        this.inputProjects.current.value = "";
        this.inputRoles.current.value = "";
        this.inputdirect.current.value = "";
        this.setState({ Projects: '', Roles: '', Skills: '', inputValue: '', direct:'' });
    };

    render() {
        //const { inputValue } = this.state;
        return (
            <div style={{ borderTop: '1px solid grey', marginTop: '1rem' }}>
                <p className="mb-1 advSearchLink" style={{ marginTop: '1rem' }}>Advanced Search</p>
                <div className="row mb-3">
                    <div className="col-sm-4">
                        <label htmlFor="Projects" className="control-label advLabel">Key works and projects</label>
                        <input
                            className="form-input form-control advInput"
                            type="text"
                            id="Projects"
                            name="Projects"
                            placeholder="Key works and projects"
                            ref={this.inputProjects}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="col-sm-4">
                        <label htmlFor="Roles" className="control-label advLabel">Extra Roles & Responsibilities</label>
                        <input
                            className="form-input form-control advInput"
                            type="text"
                            id="Roles"
                            name="Roles"
                            placeholder="Extra Roles & Responsibilities"
                            ref={this.inputRoles}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="col-sm-4">
                        <label htmlFor="Skills" className="control-label advLabel">Skills</label>
                        <CreatableSelect
                            components={components}
                            isMulti
                            onChange={this.handleChange}
                            options={this.state.skillOptions}
                            createOptionPosition={"first"}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            formatCreateLabel={() => this.state.inputValue}
                            onInputChange={this.handleInputChangeSearch}
                            placeholder="Type skills and press enter..."
                            isClearable={true}
                            className="inpSkill"
                            styles={customStyles}
                            name="Skills"
                            value={(this.state.Skills === "" ? "" : this.state.Skills.split(",").map(function (label, value) {
                                return { label: label, value: label };
                            }))}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-4">
                        <label htmlFor="direct" className="control-label advLabel">Directorate</label>
                        <input hidden
                            className="form-input form-control advInput"
                            type="text"
                            id="direct"
                            name="direct"
                            placeholder="Directorate"
                            ref={this.inputdirect}
                            onChange={this.handleInputChange}
                        />
                        <Select
                            className="advInput"
                            type="text"
                            id="direct"
                            name="direct"
                            value={this.state.direct}
                            placeholder="Directorate"
                            ref={this.inputdirect}
                            onChange={this.handleOnChange}
                            options={this.state.directOptions}
                        />
                    </div>
                    <div className="col-sm-8">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <button className="btn btn-primary btnSearch" onClick={() => this.props.advanceSearchButton(this.state.Projects, this.state.Roles, this.state.Skills, this.state.direct.label)}>Search</button>
                    </div>
                    <div className="col-md-10 divSearchLink">
                        <a href="#" className="aSearchLink" onClick={(e) => { this.clearSearch(); e.preventDefault(); }}>Clear Search</a>
                    </div>
                </div>
            </div>
        )
    }
}