import React, { Component } from 'react';
import { CardImg } from 'reactstrap';
import { authProvider } from '../../authProvider';
import { getLoggedInUser, getUserByEmailId, getUserStatus } from '../Services/GraphService';
import { getActiveAccessToken } from '../Services/TokenService';
import { PresenceTimeout } from '../Login/config';
import '../scss/staff.css';

// Presence icons
import PresenceAvailable from '../images/Presence-Available-Taskbar.png';
import PresenceAway from '../images/Presence-Away-Taskbar.png';
import PresenceBusy from '../images/Presence-Busy-Taskbar.png';
import PresenceDND from '../images/Presence-DND-Taskbar.png';
import PresenceOffline from '../images/Presence-Offline-Taskbar.png';
import PresenceUnknown from '../images/Presence-Unknown-Taskbar.png';

export class PresenceStatus extends Component {
    constructor(props) {
        super(props);
        var gUser = getLoggedInUser();

        this.state = {
            emailId: props.emailId,
            PresenceIcon: this.getSource(),
            userId: '',
            PresenceStatus: "PresenceUnknown",
            PresenceActivity: 'Presence unknown',
            isStatusNeeded: gUser.providerId !== "Google"
        };

        this.getStatus = this.getStatus.bind(this);

        if (this.state.isStatusNeeded) {
            setInterval(() => { this.getStatus(); }, PresenceTimeout);
            this.getStatus();
        }
    }

    getSource = (status) => {
        switch (status) {
            case "Available": return PresenceAvailable;
            case "AvailableIdle": return PresenceAvailable;
            case "Away": return PresenceAway;
            case "BeRightBack": return PresenceAway;
            case "Busy": return PresenceBusy;
            case "BusyIdle": return PresenceBusy;
            case "DoNotDisturb": return PresenceDND;
            case "Offline": return PresenceOffline;
            case "PresenceUnknown": return PresenceUnknown;
            default: return PresenceUnknown;
        }
    }

    getActivity = (activity) => {
        switch (activity) {
            case "BeRightBack": return "Be right back";
            case "DoNotDisturb": return "Do not disturb";
            case "InACall": return "In a call";
            case "InAConferenceCall": return "In a conference call";
            case "InAMeeting": return "In a meeting";
            case "OffWork": return "Off work";
            case "OutOfOffice": return "Out of office";
            case "PresenceUnknown": return "Presence unknown";
            case "UrgentInterruptionsOnly": return "Urgent interruptions only";
            default: return activity;
        }
    }

    async getStatus() {
        try {
            var accessToken = await getActiveAccessToken(authProvider);

            if (this.state.userId === '') {
                var user = await getUserByEmailId(this.state.emailId, accessToken)
                    .then(function (response) {
                        if (response.ok) {
                            return response.json()
                        }
                    });

                if (user === undefined) {
                    this.setState({
                        PresenceIcon: PresenceUnknown, userId: '', PresenceStatus: 'PresenceUnknown', PresenceActivity: 'Presence unknown'
                    });
                    return;
                }
                else if (user) {
                    var userStatus = await getUserStatus(user.id, accessToken)
                        .then(function (response) {
                            if (response.ok) {
                                return response.json()
                            }
                        });

                    if (userStatus) {
                        var icon = this.getSource(userStatus.availability);
                        var act = this.getActivity(userStatus.activity);
                        this.setState({ PresenceIcon: icon, userId: user.id, PresenceStatus: userStatus.activity, PresenceActivity: act });
                    }
                }
            }
            else {
                var userStatus = await getUserStatus(this.state.userId, accessToken)
                    .then(function (response) {
                        if (response.ok) {
                            return response.json()
                        }
                    });

                if (userStatus) {
                    if (this.state.PresenceStatus !== userStatus.activity) {
                        var icon = this.getSource(userStatus.availability);
                        var act = this.getActivity(userStatus.activity);
                        this.setState({ PresenceIcon: icon, PresenceStatus: userStatus.activity, PresenceActivity: act });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (this.state.isStatusNeeded) {
            this.setState({ emailId: nextProps.emailId, userId: '', PresenceStatus: 'PresenceUnknown', PresenceActivity: 'Presence unknown' });
            await this.getStatus();
        }
    }

    render() {
        if (this.state.isStatusNeeded) {
            return (
                <CardImg src={this.state.PresenceIcon} alt="NA" title={this.state.PresenceActivity} className={this.props.isSmall ? "cardMenuIconSmall img-fluid" : "presenceIconBig img-fluid"} />
            );
        }
        else {
            return <span></span>;
        }
    }
}
