import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { GoogleLogin } from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import '../scss/staff.css';
import { Welcome } from '../Welcome';
import { authProvider } from '../../authProvider';
import { getUserDetails, getUserPhoto, getLoggedInUser } from '../Services/GraphService';
import { Google } from './config';
import './Login.css';


export default class Login extends Component {
    //static displayName = App.name;

    constructor(props) {
        super(props);
        var autoLogin = 0;
        var gUser = getLoggedInUser();
        if (gUser === null) {
            var user = authProvider.getAccount();
            if (user) {
                autoLogin = 1;
            }
        }
        else if (gUser.providerId === "Office365") {
            var user = authProvider.getAccount();
            if (user) {
                autoLogin = 1;
            }
        }
        else if (gUser.providerId === "Google") {
            autoLogin = 2;
        }

        this.state = {
            isLoading: true,
            user: null,
            isMsUser: false,
            userGmail: '',
            isAutologin: autoLogin
        };

        this.signup = this.signup.bind(this);
        this.printUserInfo = this.printUserInfo.bind(this);
    }

    async componentDidMount() {

        var gUser = getLoggedInUser();
        if (gUser === null) {
            var user = authProvider.getAccount();
            if (user) {
                await this.getMyProfile();
            }
        }
        else if (gUser.providerId === "Office365") {
            var user = authProvider.getAccount();
            if (user) {
                await this.getMyProfile();
            }
        }
        this.setState({ isLoading: false });
    }

    signup(res) {
        const googleresponse = {
            Name: res.profileObj.givenName,
            email: res.profileObj.email,
            token: res.googleId,
            photo: res.profileObj.imageUrl,
            ProviderId: 'Google'
        };
        if (res.profileObj !== undefined || res.profileObj !== null)
            this.setState({ isAutologin: 2 });
        sessionStorage.setItem("userData", JSON.stringify(googleresponse));
        window.location = window.location.origin + '/Home';
    }

    async login() {
        await authProvider.login();
    }

    async getMyProfile() {

        try {
            var accessToken = await authProvider.getAccessToken();
            if (accessToken) {
                var userDetail = await getUserDetails("me", accessToken);
                var photoDetail = await this.getMyPhoto(accessToken);
                sessionStorage.setItem("msUserDetail", JSON.stringify(userDetail));
                sessionStorage.setItem("msPhotoDetail", (photoDetail === undefined ? '' : photoDetail));
                this.setState({
                    isLoading: true,
                    user: userDetail,
                    photo: photoDetail,
                    isMsUser: true,
                    userGmail: '',
                    isAutologin: 1
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    async getMyPhoto(accessToken) {
        return await getUserPhoto("me", accessToken).then(
            function (response) {
                if (response.ok) {
                    return response.blob();
                }
            })
            .then(
                function (photoBlob) {
                    if (photoBlob) {
                        return new Promise((resolve, reject) => {
                            let content = '';
                            const reader = new FileReader();
                            // Wait till complete
                            reader.onloadend = function (e) {
                                content = e.target.result;
                                resolve(content);
                            };
                            // Make sure to handle error states
                            reader.onerror = function (e) {
                                reject(e);
                            };
                            reader.readAsDataURL(photoBlob);
                        });
                    }
                });
    }

    printUserInfo = (userInfo) => {
        window.location = window.location.origin + '/Home';
    };

    render() {
        const responseGoogle = (response) => {
            if (response.error) { }
            else {
                this.signup(response);
            }
        };

        //Google log in disabled 

        let azureLogin;
//      let googleLogin;
        if (this.state.isMsUser === true) {
            azureLogin = <AzureAD provider={authProvider} accountInfoCallback={this.printUserInfo} >
            </AzureAD>;
//          googleLogin = <span></span>;
        }
        else {
            azureLogin = <button className="btn btn-primary btn-lg btn-block LoginButton" onClick={this.login}>
                Log in
                        </button>;
 /*           googleLogin = <GoogleLogin
                clientId={Google.CLIENTID}
                render={this.state.isAutologin === 0 ?
                    renderProps => (
                        <div>
                            <button className="btn btn-primary btn-lg btn-block LoginButton" onClick={renderProps.onClick} disabled={renderProps.disabled}>NHSX User</button>
                        </div>) :
                    renderProps => (
                        <ReactLoading type={"spokes"} color={"#000"} height={'7%'} width={'7%'} className="loading-gif" disabled={renderProps.disabled} />)}
                buttonText="NHSX User"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true} />; */
        }

        if (this.state.isLoading === false) {
            let loginBody
            if (this.state.isAutologin === 0) {
                loginBody = <div>
                    <a href="/Home" />
                    <h6>Click below to sign in with your @england.nhs.uk account</h6>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            {azureLogin}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <span className="bold">Please Note</span> Legacy NHS Digital colleagues are currently not searchable in the NHS England staff directory.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <span className="bold">Legacy NHS Digital colleagues</span> currently you're unable to access the NHS England staff directory. Our IT teams are working to address this.
                        </div>
                    </div>
                </div>;
            }
            else if (this.state.isAutologin === 1 && this.state.isMsUser) {
                loginBody = azureLogin;
            }
/*            else if (this.state.isAutologin === 2 && !this.state.isMsUser) {
                loginBody = googleLogin;
            }
*/            
            else
                loginBody = <ReactLoading type={"spokes"} color={"#000"} height={'7%'} width={'7%'} className="loading-gif" />;

            return (loginBody);
        }
        else {
            return (
                <ReactLoading type={"spokes"} color={"#000"} height={'7%'} width={'7%'} className="loading-gif" />
            )
        }
    }
}