import { MsalAuthProvider, LoginType } from 'react-aad-msal';

import { MsConfig, AuthParameters} from './components/Login/config';

export const authOptions = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
};

export const authProvider = new MsalAuthProvider(MsConfig, AuthParameters, authOptions);
