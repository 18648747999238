import { authProvider, } from '../../authProvider';
import { AuthParameters } from '../Login/config';

export async function getActiveAccessToken(provider) {
    var accessToken;
    var user = provider.getAccount();
    const accessTokenRequest = {
        scopes: AuthParameters.scopes,
        account: user
    };

    await authProvider.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
        accessToken = accessTokenResponse;
        //console.log('AD is active : ' + new Date());
        return accessTokenResponse;
    }).catch(function (error) {
        console.log(error);
        console.log('AD Timeout at ' + new Date());
    });

    return accessToken;
}