var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken.accessToken);
        }
    });

    return client;
}

export async function getUserDetails(userId, accessToken) {

    const client = getAuthenticatedClient(accessToken);

    const user = await client.api('/' + userId).get();

    return user;
}

export async function getUserPhoto(userId, accessToken) {
    var url = 'https://graph.microsoft.com/beta/' + userId + '/photo/$value';

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + accessToken.accessToken,
            'Content-Type': 'application/json',
        },
    });

}

export function getLoggedInUser() {
    var gUser = null;
    const gData = JSON.parse(sessionStorage.getItem('userData'));
    if (gData != null) {
        gUser = {};
        gUser.givenName = gData.Name;
        gUser.mail = gData.email;
        gUser.photo = gData.photo;
        gUser.providerId = gData.ProviderId;
    }
    else {
        const msUserDetail = JSON.parse(sessionStorage.getItem("msUserDetail"));
        const msPhotoDetail = sessionStorage.getItem("msPhotoDetail");
        if (msUserDetail !== null) {
            gUser = {};
            gUser = msUserDetail;
            gUser.photo = msPhotoDetail === undefined ? '' : msPhotoDetail;
            gUser.providerId = "Office365";
        }
    }

    return gUser;
}

export async function getUserByEmailId(userId, accessToken) {
    var url = 'https://graph.microsoft.com/v1.0/users/' + userId;

    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + accessToken.accessToken,
            'Content-Type': 'application/json',
        },
    }).then(function (response) {
        if (response.ok)
            return response;
        else return {};
    }).catch(function (ex) {
        console.log(ex);
        return {};
    });
}

export async function getUserStatus(userId, accessToken) {
    //var url = 'https://graph.microsoft.com/beta/' + userId + '/presence';
    var url = 'https://graph.microsoft.com/beta/users/' + userId + '/presence';
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + accessToken.accessToken,
            'Content-Type': 'application/json',
        },
    }).then(function (response) {
        return response;
    }).catch(function (ex) {
        console.log(ex);
        return {};
    });
}

export async function getMultipleUserStatus(userIds, accessToken) {
    var url = 'https://graph.microsoft.com/beta/photo/$value';

    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + accessToken.accessToken,
            'Content-Type': 'application/json',
        },
        body: userIds,// user Ids
    });
}