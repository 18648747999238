export const Google = {
    REDIRECT_URI: 'http://localhost:3000/oauth_callback',
    SCOPE: 'openid profile email',
    CLIENTID:'221242527617-q492mai7qf4pigso40h4t82q3pelmhah.apps.googleusercontent.com'
};

export const MsConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/Common/',
        clientId: '3e843742-34cc-4450-be91-785f5d3c1acf', //Application (client) ID from Overview blade in App Registration
        redirectUri: 'https://devstaffdirectory.azurewebsites.net/',
        postLogoutRedirectUri: 'https://devstaffdirectory.azurewebsites.net/'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
};

export const AuthParameters = {
    scopes: ['email', 'openid', 'profile', 'User.Read', 'Presence.Read', 'Presence.Read.All', 'User.ReadBasic.All']
};

//60000 milliseconds = 1 minute
export const PresenceTimeout = 120000;
