import React, { Component } from 'react';
import About_us from '../images/About_us.png'
import home from '../images/home.png'
import FAQs from '../images/FAQs.png'
import NHSBlueonwhite from '../images/NHSEBlue.jpg';
import notificationImg from '../images/notification.png';
import './Layout.css';
import { authProvider } from '../../authProvider';
import { getLoggedInUser } from '../Services/GraphService';
import Menu, { Button, Dropdown, DropdownItem } from "@kenshooui/react-menu";
import { GoogleLogout } from 'react-google-login';
import { Google } from '../Login/config';
import user from '../images/userLight.png';
import Feedback from '../images/Customer_Feedback.png';
import Recruitment from '../images/Recruitment-02.png';

const menus = [
    { id: "Id1", name: "Home", to: "/Home", className: "", imgSrc: home, target: "_self" },
    { id: "Id2", name: "About", to: "/About", className: "", imgSrc: About_us, target: "_self" },
    { id: "Id3", name: "FAQ", to: "/FAQ", className: "", imgSrc: FAQs, target: "_self" },
    { id: "Id4", name: "Feedback", to: "https://forms.office.com/Pages/ResponsePage.aspx?id=q3OYhGVC-UazLT-k-7l-oYjjJWH9KJtJtLwhSoZGlfdUQUZaRVFWR1hTTkNTRlpIWFVDV0VTSjE2NS4u", className: "", imgSrc: Feedback, target: "_blank" },
    { id: "Id5", name: "Internal Recruitment", to: "https://nhsengland.sharepoint.com/sites/HRandOD/SitePages/Expressions-of-Interest-Landing.aspx", className: "", imgSrc: Recruitment, target: "_blank" }
];

export default class Layout extends Component {

    constructor(props) {

        var gUser = getLoggedInUser();

        var guestUser = {};
        guestUser.givenName = 'Guest';
        guestUser.mail = '';
        guestUser.photo = null;

        super(props);
        var activeMenu = this.getActiveMenu();
        this.state = {
            drawerClasses: 'wrapper',
            activeMenu: true,
            showLogout: '',
            showMain: '',
            activelinkId: activeMenu,
            user: gUser == null ? (props.user == null ? guestUser : props.user) : gUser,
            photo: gUser == null ? (props.photo === null || props.photo === '' || props.photo === undefined ? user : props.photo) : (gUser.photo === undefined || gUser.photo === null || gUser.photo === '') ? user : gUser.photo,
            providerId: gUser == null ? "Office365" : gUser.providerId,
            isLoggedIn: gUser == null ? (props.user == null ? false : true) : true
        };

        this.logout = this.logout.bind(this);
        this.logoutFailure = this.logoutFailure.bind(this);
        this.msLogout = this.msLogout.bind(this);
    }

    getActiveMenu = () => {
        var tt = menus.filter(o => window.location.pathname.includes(o.name));
        return tt.length > 0 ? tt[0].id : 1;
    }

    toggleSidebar = () => {
        let reqClass = '';
        if (this.state.drawerClasses.indexOf('collapseBar') > -1) {
            reqClass = 'wrapper';
        }
        else {
            reqClass = 'wrapper collapseBar'
        }
        this.setState({ drawerClasses: reqClass });
    }

    showLogOut = () => {
        let logBox = '';
        if (this.state.showLogout.indexOf('show_dropdownBox') > -1) {
            logBox = '';
        }
        else {
            logBox = 'show_dropdownBox'
        }

        this.setState({ showLogout: logBox });
    }

    componentDidMount() {
        document.body.addEventListener("click", this.onClickOutside);
    }

    componentWillUnmount() {
        // delete click event
        document.removeEventListener("click", this.onClickOutside);
    }

    onClickOutside = e => {
        const element = e.target;
        if (this.state.showLogout.indexOf('show_dropdownBox') > -1) {
            e.preventDefault();
            e.stopPropagation();

            this.showLogOut();
            return null;
        }
    };

    logout = (response) => {
        sessionStorage.removeItem('userData');
        window.location = "/";
    }

    logoutFailure = (response) => {
        alert('Log out failed');
    }

    msLogout = () => {
        sessionStorage.removeItem('msUserDetail');
        sessionStorage.removeItem('msPhotoDetail');
        authProvider.logout();
    }

    render() {
        let menuBar = null;
        if (this.state.isLoggedIn === false) {
            menuBar = <li>
                <a href="/" id="Id1" className="active">
                    <span className="icon">
                        <i className="fas fa-book">
                            <img alt="" src={About_us} className="bm-menu-icons active" />
                        </i>
                    </span>
                    <span className="title">Login</span>
                </a>
            </li>;
        }
        else {
            menuBar = menus.map((menu, _index) => {
                return (
                    <li key={_index}><a rel="noopener noreferrer" href={menu.to} target={menu.target} id={menu.id} className={(this.state.activelinkId == menu.id ? menu.className + " active" : menu.className)}>
                        <span className="icon"><i className="fas fa-book"><img alt="" src={menu.imgSrc} className="bm-menu-icons active" /></i></span>
                        <span className="title">{menu.name}</span></a></li>
                )
            });
        }

        let logOutButton = null;
        if (this.state.isLoggedIn === false) {
            logOutButton = <DropdownItem className="menuColor show_dropdownBox pad-6 underlineText">Login</DropdownItem>
        }
        else if (this.state.providerId === "Office365") {
            logOutButton = <DropdownItem className="menuColor show_dropdownBox pad-6 underlineText" onClick={() => { this.msLogout(); }}>Log Out</DropdownItem>
        }
        else if (this.state.providerId === "Google") {
            logOutButton = <GoogleLogout
                clientId={Google.CLIENTID}
                render={renderProps => (
                    <DropdownItem className="menuColor show_dropdownBox pad-6 underlineText" onClick={renderProps.onClick} disabled={renderProps.disabled}>Log Out</DropdownItem>
                )}
                buttonText="Logout"
                onLogoutSuccess={this.logout}
                onFailure={this.logoutFailure} />
        }

        return (
            <div style={{ height: '100%' }}>
                <div className={this.state.drawerClasses}>
                    <div className="top_navbar">
                        <div className="hamburger" onClick={this.toggleSidebar}>
                            <div className="one"></div>
                            <div className="two"></div>
                            <div className="three"></div>
                        </div>
                        <div className="top_menu">
                            <a name="abc" href={this.state.isLoggedIn ? "/Home" : "/"}><div className="logoBrand"><img src={NHSBlueonwhite} alt="NHSBlueonwhite" /></div></a>
                            <ul>
                                <li hidden>
                                    <a href="#" onClick={(e) => { e.preventDefault(); }} >
                                        <i className="fas fa-bell"><img alt="" src={notificationImg} href="#" className="notifMenu" /></i>
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="dcf"  href={this.state.isLoggedIn ? "/Home" : "/"}>  
                                        <i  className="fas fa-bell"><img alt="" src={this.state.photo} href="#" className="bm-menu-icons helpMenu" /></i>
                                    </a>
                                </li>
                                <li className="navdropdown">
                                    <Menu className="menuColor">
                                        <Dropdown label={"Hi " + this.state.user.givenName} className="menuColor">
                                            {logOutButton}
                                        </Dropdown>
                                    </Menu>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="sidebar">
                        <ul>
                            {menuBar}
                        </ul>
                    </div>

                    <div className="main_container">
                        <div className="main_panel" >
                            {this.props.children}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
