// JavaScript source code
import React, { Component, createRef, useState, useEffect } from 'react';
import './scss/staff.css';
import { StaffList } from './StaffList/StaffList';
import { Staff } from './Staff/Staff';
import { Filter } from './Filter/Filter';
import search from './images/search.png'
import clearSearch from './images/Cancel-hover-24x24.png'
import { getLoggedInUser } from './Services/GraphService';
import CryptoES from 'crypto-js';
import Modal from 'react-modal';
import NHSBlueonwhite from './images/NHSEBlue.jpg';
import Select, { components } from 'react-select';
import { clone } from "lodash";
import historyImg from './images/history-icon.png';
import ReactLoading from 'react-loading';
const secret = "hhjhj%&*£kjhdsfjhk328098!";

const borderStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: '#3860b2',
        borderRadius: '2rem',
        height: 35,
        minHeight: 35
    })
};
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '37%'
    }
};

function isIncludingString(string, option) {
    let result = false;
    if (
        !string ||
        option.label.toString().includes(string) ||
        option.value.toString().includes(string)
    ) {
        result = true;
    }
    return result;
}

export class Welcome extends Component {

    static displayName = Welcome.name;
    constructor(props) {
        super(props);
        var gUser = getLoggedInUser();
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let user = params.get('user');
        if (gUser === null && user === null)
            props.history.push('/');

        let mainMail = gUser !== null ? gUser.providerId === "Google"
            ? gUser.mail
            : gUser.userPrincipalName === null ? gUser.mail : gUser.userPrincipalName : null;

        if (performance.navigation.type == 2) {
            window.location.reload(true);
        }

        this.state = {
            email: gUser == null ? props.email : mainMail,
            staff: [],
            name: '',
            isSearched: false,
            isSingleStaff: false,
            showSearch: false,
            staffSearchData: [],
            query: '',
            key: 0,
            photo: gUser == null ? props.photo : gUser.photo,
            showAdvSearch: false,
            dataExist: true,
            browserAction: 'Push',
            cursor: 0,
            open: false,
            site1: '',
            site: '',
            sites: [],
            CustomOption: [],
            searchField: null,
            optionsRecently: [],
            loading: true,
        };
        this.inputRef = createRef();  // creating ref n
        this.advSearch = createRef();
        this.state.staffDetail = {};
        this.loadSingleStaff(this.state.email);
        this.navigate = this.props.navigate;
        this.loadSingleStaff = this.loadSingleStaff.bind(this);
        this.SearchButtonClick = this.SearchButtonClick.bind(this);
        this.SearchButton = this.SearchButton.bind(this);
  
        this.escFunction = this.escFunction.bind(this);
        this.advanceSearchButton = this.advanceSearchButton.bind(this);
        this.clearAdvanceSearch = this.clearAdvanceSearch.bind(this);
        this.toggleAdvSearch = this.toggleAdvSearch.bind(this);
        
        (async () => fetch('api/Staff/GetHistory?email=' + this.state.email)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    optionsRecently: data
                });
            }))();

    }

    //Esc click event
    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ ...this.state, showSearch: false });
            return null;
        }

    }
    //region 
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() { 
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate() {
        window.onpopstate = e => {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let userEncrypt = params.get('user');
            const userDecrypt = CryptoES.AES.decrypt(userEncrypt, secret);
            const user = userDecrypt !== null ? userDecrypt.toString(CryptoES.enc.Utf8) : null;
            if (user !== null) {
                this.setState({ browserAction: 'Pop' });

                if (user.indexOf("nhs.net") === -1)
                    this.loadSingleStaff(user);
                else
                    this.loadSingleStaff(null, user);
            }
        }
    }

    call = e => {
        (async () => fetch('api/Staff/GetHistory?email=' + this.state.email)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    optionsRecently: data
                });
            }))();
    }
    // Out click end
    SearchButtonClick = e => {
        if (this.state.searchField !== "") { this.SearchButton(); } else {
            if (this.state.searchField === "") { }
        }
    };

    SearchButton() {
        this.setState({ isSearched: false });
        (async () => fetch('api/Staff/Search?name=' + this.state.searchField)
            .then(response => response.json())
            .then(data => {
                if (data != this.state.staff) {
                    this.setState({ staff: data, isSearched: true, isSingleStaff: false });
                }
                else {
                    this.setState({ staff: data, isSearched: true, isSingleStaff: false });
                }
            }))();
    };

    toggleAdvSearch() {
        var tt = !this.state.showAdvSearch;
        this.setState({ showAdvSearch: tt });
    };

    advanceSearchButton(pr, rl, sk, dr) {
        if (pr === '' && rl === '' && sk === '' && dr === '')
            return;
        this.setState({ isSearched: false });
        (async () => fetch('api/Staff/AdvanceSearch?projects=' + pr + '&roles=' + rl + '&skills=' + sk + '&direct=' + dr)
            .then(response => response.json())
            .then(data => {
                if (data != this.state.staff) {
                    this.setState({ staff: data, isSearched: true, isSingleStaff: false, query: '' });
                }
                else {
                    this.setState({ staff: data, isSearched: true, isSingleStaff: false, query: '' });
                }
            }))();
    };

    clearAdvanceSearch() {
        this.loadSingleStaff(this.state.email);
    }

    setValue = (field, event) => {

        //If the input fields were directly within this
        //this component, we could use this.refs.[FIELD].value
        //Instead, we want to save the data for when the form is submitted
        var object = {};
        object[field] = event.target.value;
        this.setState({ name: event.target.value, staffSearchData: [] });
    };

    loadSingleStaff = (empEmail, empNo, searchHistory) => {
        this.call();
        var randomKey = Math.random();
        let loggedInUser = false;

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let userEncrypt = params.get('user');
        //const user = userEncrypt !== null ? (userEncrypt.includes("nhs.net") || userEncrypt.includes("nhsx")) ? userEncrypt : CryptoES.AES.decrypt(userEncrypt, "Secret Passphrase") : null;
        const userDecrypt = userEncrypt !== null ? CryptoES.AES.decrypt(userEncrypt, secret) : null;
        const user = userDecrypt !== null ? userDecrypt.toString(CryptoES.enc.Utf8) : null;

        if (user !== null && (empNo === null || empNo === undefined)) {
            if (user.indexOf("nhs.net") === -1) {
                (async () => fetch('api/Staff/GetByEmployeeEmail?Email=' + user)
                    .then(response => response.json())
                    .then(data => {
                        //this.advSearch.current.clearSearch();
                        this.setState({ staffDetail: data, isSingleStaff: true, isSearched: false, showSearch: false, isEdit: this.state.email !== undefined ? this.state.email.toString().toLowerCase() === data.login_Address.toString().toLowerCase() : false, key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                    }))();
            } else {
                (async () => fetch('api/Staff/GetByEmployeeNhsEmail?Email=' + user)
                    .then(response => response.json())
                    .then(data => {
                        //this.advSearch.current.clearSearch();
                        this.setState({ staffDetail: data, isSingleStaff: true, isSearched: false, showSearch: false, isEdit: this.state.email !== undefined ? this.state.email.toString().toLowerCase() === data.login_Address.toString().toLowerCase() : false, key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                    }))();
            }
        }
        else if (empEmail != "" && empEmail != null) {
            (async () => fetch('api/Staff/GetByEmail?Email=' + empEmail, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "photo": this.state.photo,
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data == null) {
                        this.setState({ dataExist: false });
                    }
                    if (this.advSearch !== null && this.advSearch.current !== null)
                        this.advSearch.current.clearSearch();
                    if (this.state.browserAction !== 'Pop' && data !== null) {
                        const encryptedString = encodeURIComponent(CryptoES.AES.encrypt(data.employee_Email_Address, secret).toString());
                        this.props.history.push({
                            pathname: '/Home',
                            search: '?user=' + encryptedString
                        })
                    }
                    this.setState({ staffDetail: data, isSingleStaff: true, isSearched: false, showSearch: false, isEdit: this.state.email.toString().toLowerCase() === data.login_Address.toString().toLowerCase(), key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                }))();
        }
        else if (empNo != "" && empNo != null) {
            if (this.state.browserAction !== 'Pop') {
                const encryptedString = encodeURIComponent(CryptoES.AES.encrypt(empNo, secret).toString());
                this.props.history.push({
                    pathname: '/Home',
                    search: '?user=' + encryptedString
                })
            }

            if (empNo.indexOf("nhs.net") === -1) {
                (async () => fetch('api/Staff/GetByEmployeeEmail?Email=' + empNo)
                    .then(response => response.json())
                    .then(data => {
                        //this.advSearch.current.clearSearch();
                        this.setState({ staffDetail: data, isSingleStaff: true, isSearched: false, showSearch: false, isEdit: this.state.email.toString().toLowerCase() === data.login_Address.toString().toLowerCase(), key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                    }))();
            }
            else {
                (async () => fetch('api/Staff/GetByEmployeeNhsEmail?Email=' + empNo)
                    .then(response => response.json())
                    .then(data => {
                        //this.advSearch.current.clearSearch();
                        this.setState({ staffDetail: data, isSingleStaff: true, isSearched: false, showSearch: false, isEdit: this.state.email.toString().toLowerCase() === data.login_Address.toString().toLowerCase(), key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                    }))();
            }     
        }
        if (searchHistory) {
            (async () => fetch('api/Staff/addHistory?Email=' + this.state.email + '&empEmail=' + empNo)
                .then(response => response.json())
                .then(data => {
                    this.setState({ isSingleStaff: true, isSearched: false, showSearch: false, key: randomKey, showAdvSearch: false, browserAction: 'Push', loading: false });
                }))();
            this.call();
        }
        this.call();

    };

    onChange = (opt, { sites }) => {
        let newOpts = opt;
        let string = this.state.searchField;

        if (sites && sites.value === "all") {
           
            let filteredOptions = clone(sites);

            filteredOptions = filteredOptions.filter(
                filteredOption =>
                    isIncludingString(string, filteredOption) &&
                    !newOpts.includes(filteredOption)
            );

            string = null;
            newOpts = newOpts
                .concat(filteredOptions)
                .filter(newOpt => newOpt.value !== "all");
        }
        //string.length > 3 && 
        if (newOpts.value !== "all") {
            this.loadSingleStaff(null, newOpts.value, true);
        } else if (newOpts.value === "all") {
            this.SearchButtonClick();
        }

        this.setState({
            searchField: string,
            values: '',
            sites: []
        });
    };
    onInputChange1 = (string, { action }) => {

        (async () => fetch('api/Staff/GetHistory?email=' + this.state.email)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    optionsRecently: data
                });
            }))();

        this.setState({ sites: [] });
        if (action === "input-change") {
            if (string.length > 3) {
                (async () => fetch('api/Staff/SearchBlur?name=' + string)
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ sites: data, loadStaff: false, showSearch: true })
                    }))();

            } else {
                //this.setState({ sites: [] });
            }
            this.setState({
                searchField: string
            });
        }
    };

    render() {

        const isLoaded = this.state.isSearched;
        let { query, CustomOption, sites, optionsRecently } = this.state;
        const dataExist = this.state.dataExist;

        CustomOption = props => {
            const { data, innerRef, innerProps } = props;
            return (
                <components.Option {...props}>
                    <div className={props.data.value === 'all' ? 'custom-opt' : ''}>{props.data.label}
                        <span style={{ fontSize: 13 }}>{props.data.subLabel}</span>
                    </div>
                </components.Option>
            );
        };

        let CustomOption1 = props => {
            const { data, innerRef, innerProps } = props;
            return (
                <components.Option {...props}>

                    <div className={props.data.value === 'all' ? 'custom-opt' : ''}>
                        <img alt="" src={historyImg} className="historyIcon history-icon" />{props.data.label}<span>, </span>
                        <span style={{ fontSize: 13 }}>{props.data.subLabel}</span>
                    </div>
                </components.Option>
            );
        };
        let filterOption = ({ label, value }, string) => {
            if (value === "all") {
                return true;
            } else if (string) {
                return label.includes(string) || value.toString().includes(string);
            } else {
                return true;
            }
        };



        let contents = this.state.loading
            ? <div><ReactLoading type={"spokes"} color={"#000"} height={'7%'} width={'7%'} className="loading-gif" /></div> :
       
            <div className="body">
                <div className="divSearchMain">
                    <div className="row">
                        <div className="col-sm-8 input-wrap">  
                            <Select
                                filterOption={filterOption}
                                options={this.state.sites.length > 0 ? this.state.sites : optionsRecently}
                                value={this.state.values}
                                onInputChange={this.onInputChange1}
                                onChange={this.onChange}
                                styles={borderStyles}
                                placeholder="Search"
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Option: this.state.sites.length > 0 ? CustomOption : CustomOption1 }}

                            >

                            </Select>
                            <a title="See all results" href="#" onClick={(e) => { this.SearchButtonClick(); e.preventDefault(); }}><img alt="" className="follows-input" src={search} /></a>
                          
                        </div>
                        <div className="col-sm-4" >
                            <button className="searchButton" type="button" hidden onClick={(e) => { this.SearchButtonClick(); e.preventDefault(); }}>Search</button>
                            {dataExist ? <a href="#" className="aSearchLink" onClick={(e) => { this.toggleAdvSearch(); e.preventDefault(); }} >Advanced Search</a> : null}
                            {
                                this.state.showAdvSearch ?
                                    <a title="Clear result" href="#" className="aClearSearchLink" onClick={(e) => { this.clearAdvanceSearch(); e.preventDefault(); }}><img alt="" className="follows-input aClearSearchImg" src={clearSearch} /></a>
                                    : <div></div>
                            }
                        </div>
                    </div>
                    <div className={this.state.showAdvSearch ? "row" : "row hidden"}>
                        <div className="col-sm-12 input-wrap">
                            <Filter advanceSearchButton={this.advanceSearchButton} ref={this.advSearch} />
                        </div>
                    </div>
                </div>

                {dataExist ?
                    <div>
                        {
                            isLoaded && <div>{this.state.query === '' || this.state.query === undefined || this.state.query === null ? "" : "Result for \"" + this.state.query + "\""}
                                {this.state.staff.length > 0 ? <StaffList staffData={this.state.staff} load={false} loadSingleStaff={this.loadSingleStaff} /> : <div className="alert alert-secondary mt-3" role="alert">No records found</div>}</div>
                        }
                        {
                            this.state.isSingleStaff && <div key={this.state.key}> <Staff staffDetail={this.state.staffDetail} load={false} loadSingleStaff={this.loadSingleStaff} isEdit={this.state.isEdit} /></div>
                        }
                    </div>
                    :
                    this.state.email.toLowerCase().includes('improvement') ? <div>
                        <Modal
                            isOpen={true}
                            style={customStyles}
                            contentLabel="Example Modal">
                            <div ><img className="widthSet" src={NHSBlueonwhite} alt="NHSBlueonwhite" /></div>
                            <div> <p className="answer">
                                <br />
                                <b> You have logged in with your old @improvement.nhs.uk account. Please log in with your new @england.nhs.uk account.
                            </b>
                            </p>
                            </div>
                        </Modal>
                    </div> :
                        <div>
                            <Modal
                                isOpen={true}
                                style={customStyles}
                                contentLabel="Example Modal">
                                <div ><img className="widthSet" src={NHSBlueonwhite} alt="NHSBlueonwhite" /></div>
                                <div> <p className="answer">
                                    <br />
                                    <b>Your Data is not present with Staff directory application, please contact Corporate IT on <a href="mailto:itservicedesk@nhseandi.nhs.uk">itservicedesk@nhseandi.nhs.uk</a>.
                            </b>
                                </p>
                                </div>
                            </Modal>
                        </div>
                }
            </div>
       

        return (
            <div>
                <div id="app">{contents}</div>
            </div>

        );
    }
}
