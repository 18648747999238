import React, { Component, Fragment } from 'react';
import '../scss/custom.scss';
import '../NavMenu.css';
import user from '../images/userLight.png';
import { PresenceStatus } from '../PresenceStatus/PresenceStatus';


export class StaffCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffDetail: props.staffDetail,
            name: '',
            load: props.load,
        };
        this.loadSingleStaff = this.props.loadSingleStaff;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ staffDetail: nextProps.staffDetail });
    }

    render() {
        return (
            <Fragment>
                <div className="card-group col-sm-6 top_Margin_5" >
                    <div className="card flex-row flex-wrap">
                        <div className="card-header border-0 widthSet">
                            <div style={{ position: "relative", width: "6rem" }}>
                                <img alt="" src={(this.state.staffDetail.staffData === null || this.state.staffDetail.staffData.imgdata === null || this.state.staffDetail.staffData.imgdata === '' ? user : this.state.staffDetail.staffData.imgdata)} alt="" className="staffIcon" />
                                <PresenceStatus emailId={this.state.staffDetail.login_Address} />
                            </div>
                        </div>
                        <div className="card-block px-2 widthSet1">
                            <a href="#" onClick={(e) => { this.loadSingleStaff(null, this.state.staffDetail.employee_Email_Address); e.preventDefault(); }}> <h4 className="card-title top_Margin_5" >{this.state.staffDetail.employee_Individual_Title} {this.state.staffDetail.employee_First_Name}  {this.state.staffDetail.employee_Last_Name} </h4></a>
                            <p hidden className="card-text"><b>Role : </b>{this.state.staffDetail.role}</p>
                            <p className="card-text"><b>Position Title : </b>{this.state.staffDetail.position_Title}</p>
                            <p className="card-text"><b>Email : </b>{this.state.staffDetail.employee_Email_Address}</p>
                            <p className="card-text"><b>Phone : </b>{this.state.staffDetail.work_Mobile}</p>
                            <p hidden className="card-text"><b>Organisation : </b>{this.state.staffDetail.organization_Name}</p>
                            <a hidden href="#" className="btn btn-primary">View</a>
                        </div>
                        <div className="w-100"></div>
                    </div>
                </div>
            </Fragment>);
    }
}