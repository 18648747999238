import React, { Component } from 'react';
import '../scss/custom.scss';
import '../NavMenu.css';
import { Staff } from '../Staff/Staff';
import { StaffCard } from '../StaffCard/StaffCard';
import { Pagination } from '../Pagination/Pagination';


export class StaffList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffList: props.staffData,
            name: '',
            load: props.load,
            allCountries: props.staffData,
            currentCountries: [],
            currentPage: null,
            totalPages: null
        };
        this.state.staffDetail = {};
        this.loadSingleStaff = this.props.loadSingleStaff;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ staffList: nextProps.staffData, allCountries: nextProps.staffData, });
    }

    onPageChanged = data => {
        const { allCountries } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentCountries = allCountries.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentCountries, totalPages });
    };

    render() {

        let comp;
        const {
            allCountries,
            currentCountries,
            currentPage,
            totalPages
        } = this.state;
        const totalCountries = allCountries.length;

        if (totalCountries === 0) return null;

        const headerClass = [
            "text-dark py-2 pr-4 m-0",
            currentPage ? "border-gray border-right" : ""
        ]
            .join(" ")
            .trim();

        if (this.state.load) {
            comp = <Staff staffDetail={this.state.staffDetail} loadSingleStaff={this.loadSingleStaff} isEdit='false' />;
        }
        else {
            comp = (<div>
                <div className="row d-flex flex-row" style={{ marginRight: '0px' }}>
                    {currentCountries.map((_item, _index) =>
                        <StaffCard staffDetail={_item} loadSingleStaff={this.loadSingleStaff} key={_index} />
                    )}
                </div>
                <Pagination
                    totalRecords={totalCountries}
                    pageLimit={6}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                />
            </div>);
        }
        return (<div>{comp}</div>);
    }
}