import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from './components/Login/Login';
import { AboutMe } from './components/AboutMe/AboutMe';
import { History } from './components/History/History';
import Layout from './components/Layout/Layout';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { Welcome } from './components/Welcome';
import { FAQ } from './components/FAQs/FAQ';

const Routes = () => (
    <Switch>
        <Layout>
            <Route exact path="/" component={Login} />
            <Route path="/Home" component={Welcome} />
            <Route path="/About" component={AboutMe} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/History" component={History} />
        </Layout>
    </Switch>
);

export default Routes;