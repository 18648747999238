import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImg, CardHeader, CardBody, CardText} from 'reactstrap';
import './FAQ.css';
import NeedHelp from '../images/NeedHelp.png';
import ChangePicture from '../images/FAQ_ChangePicture.png';
import ChangePicture_1 from '../images/FAQ_ChangePicture_1.png';
import CorporatePhone_1 from '../images/CorporatePhone_1.png';
import CorporatePhone_2 from '../images/CorporatePhone_2.png';
import Search from '../images/SearchFAQ.png';
import { getLoggedInUser } from '../Services/GraphService';

export class FAQ extends Component {

    constructor(props) {
        super(props);
        var gUser = getLoggedInUser();
        if (gUser === null)
            props.history.push('/');
    }

    render() {
        return (
            <div className="mainFrame">
                <div className="my-3 p-3 bg-white rounded shadow-sm">
                    <div className="media text-muted pt-3">
                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <strong className="d-block text-gray-dark question">How to update the profile picture?</strong>
                            <span className="answer ChangePictureTxt">If you are using NHS E/I Office365 account to log in to the staff directory, then you can update your picture in MS Teams.</span>
                                                       
                            <img className="ChangePicture" src={ChangePicture} alt="" width="auto" height="auto" />
                            <span className="answer ChangePicture_Text">Open MS Teams and click on your <b>account icon</b> to open the main menu.<br></br>Click the <b>Change picture</b> option.</span>

                            <img className="ChangePicture_1" src={ChangePicture_1} alt="" width="auto" height="auto" />
                            <span className="answer ChangePicture_1_Text">Click the <b>Upload picture</b> option and select the new image. Once selected click <b>Save</b>.</span>
                        </p>
                    </div>
                    <div className="media text-muted pt-3">
                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <strong className="d-block text-gray-dark question">How to update non-editable fields?</strong>
                            <span className="answer">Fields such as Employee name, Position title, Line manager, etc are generated from the NHS Electronic Staff Record (ESR). Except for NHSX employees. Their data is provided from their own staff list. Once you make these changes in your ESR profile, the changes will replicate within 24 hours onto the Staff Directory.</span>
                        </p>
                    </div>
                    <div className="media text-muted pt-3">
                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <strong className="d-block text-gray-dark question">How do I update my Corporate phone details?</strong>
                            <img className="CorporatePhone_1" src={CorporatePhone_1} alt="" width="auto" height="auto" />
                            <span className="answer CorporatePhone_1_Text">The corporate phone data is generated from the NHS E/I mobile records. Therefore, the application will show the assigned mobile number for the user. You are able to edit this section and add an alternative contact number by clicking on the edit icon.</span>
                            <img className="CorporatePhone_2" src={CorporatePhone_2} alt="" width="auto" height="auto" />
                            <span className="answer CorporatePhone_2_Text">Input an alternative number and click on the save icon.<br></br><br></br>If you do not wish to add a number or make a change you can click on the cross and it will bring you out of the edit screen.</span>
                        </p>
                    </div>
                    <div className="media text-muted pt-3">
                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <strong className="d-block text-gray-dark question">Search Functionality</strong>
                            <img className="Search" src={Search} alt="" width="auto" height="auto" />
                            <span className="answer Search_Text">You are able to use the search bar to look up a person by typing in a word related to the person you are looking for. For example if you type in the word Training, all staff members that deal with Training will appear. You can do an Advanced Search by clicking on the icon highlighted below and typing in specific role names, keywords, etc to help find the person you are looking for.</span>
                        </p>
                    </div>
                    <div className="media text-muted pt-3">
                        <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                            <strong className="d-block text-gray-dark question">Need Help?</strong>
                            <Row>
                                <Col md={2}>
                                    <img className="NeedHelp" src={NeedHelp} alt="" width="auto" height="auto" />
                                </Col>
                                <Col md={10}>
                                    <div className="NeedHelp_Text">
                                        <span className="answer">If you have any further questions or queries, please call 0300 303 4868 or email <a href="mailto:itservicedesk@nhseandi.nhs.uk">itservicedesk@nhseandi.nhs.uk</a></span>
                                    </div>
                                </Col>
                            </Row>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}