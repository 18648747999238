import React, { Component } from 'react';
import { getLoggedInUser } from '../Services/GraphService';
import './AboutMe.css';
import AboutBanner from '../images/AboutBanner01.png';

export class AboutMe extends Component {

    constructor(props) {
        super(props);
        var gUser = getLoggedInUser();
        if (gUser === null)
            props.history.push('/');
    }


    render() {
        
        return (<div className="mainFrame">
            <div className="d-flex align-items-center text-white-50 bg-purple rounded shadow-sm">
                <img alt="" src={AboutBanner} alt="" width="100%" height="auto" />
            </div>
            <div className="my-3 p-3 bg-white rounded shadow-sm">
                <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark question">Staff Directory</strong>
                        <span className="answer">The staff directory is the search engine for staff and staff information.</span>
                    </p>
                </div>
                <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark question">Which organisations are in the directory?</strong>
                        <span className="answer">NHS England, NHS Improvement, Leadership Academy and NHSX.</span>
                    </p>
                </div>
                <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark question">Where does the data come from?</strong>
                        <span className="answer">Most of the information comes from the NHS Electronic Staff Record (ESR) except for NHSX where the data is provided from their own staff list.
                        <br />The data is enhanced from Corporate IT mobile phone and other data.</span>
                    </p>
                </div>
                <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark question">My data is wrong, how do I get it corrected?</strong>
                        <span className="answer"> For non-editable fields, the majority are taken from ESR so please check your ESR records first.
                        <br />If it appears correctly in ESR or if your corporate phone numbers are incorrect, please contact Corporate IT on <a href="mailto:itservicedesk@nhseandi.nhs.uk">itservicedesk@nhseandi.nhs.uk</a></span>
                    </p>
                </div>
                <div className="media text-muted pt-3">
                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        <strong className="d-block text-gray-dark question">Why can I edit some fields and not others?</strong>
                        <span className="answer"> The fields you can edit are not available in other staff records, these are stored in a separate secure database.
                        <br />Only you can edit the fields for your entry, this is based on your Office 365 (Intranet) login for E, I and LA staff. For NHSX staff, this is based on your GSuite login.</span>
                    </p>
                </div>
            </div>
        </div>);
    }
}