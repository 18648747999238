import React, { Component } from "react";
import Routes from './Routes';
class App extends Component {
    constructor() {
        super();
        this.state = {
            appName: "Staff Directory : Login with Azure and Google",
            home: false
        }
    }

    render() {
        return (
            <div>
                <Routes name={this.state.appName} />
            </div>
        );
    }
}

export default App;