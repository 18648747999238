import React, { Component, createRef } from 'react';
import { Container, Row, Col, Card, CardImg, CardHeader, CardBody, CardText, Navbar, Nav, NavItem, NavLink, Input } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import Datetime from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Select, { components } from 'react-select';
import '../scss/custom.scss';
import '../scss/staff.css';
import '../NavMenu.css';
import orgImg from '../images/organisation.png';
import user from '../images/userLight.png'
import contactImg from '../images/contact.png';
import rolesImg from '../images/roles.png';
import skillImg from '../images/skills-32x32.png';
import deploymentStatusImg from '../images/deployment-32x32.png';
import projectImg from '../images/projects.png';
import aboutmeImg from '../images/about-me.png';
import deleteImg from '../images/Cancel-24x24.png';
import deleteHoverImg from '../images/Cancel-hover-24x24.png';
import editImg from '../images/edit-24x24.png';
import editHoverImg from '../images/edit-hover-24x24.png';
import saveImg from '../images/save.png';
import saveHoverImg from '../images/save-hover.png';
import esrImg from '../images/esrImg.png';
import addImg from '../images/add-hover-24x24.png';
import minusImg from '../images/minus-hover-24x24.png';
import historyImg from '../images/history-icon.png';

import { PresenceStatus } from '../PresenceStatus/PresenceStatus';

//const components = {
//    DropdownIndicator: null,
//};
const borderStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: '#3860b2',
        height: 35,
        minHeight: 35,
        width: 200,
        minWidth: 200
    })
};
const customStyles = {
    control: (base, state) => ({
        ...base,
        backgroundColor: '#FFEBEB',
        borderColor: '#3860b2',
        height: 35,
        minHeight: 35,
        width: 200,
        minWidth: 200
    })
};


export class Staff extends Component {
    constructor(props) {
        super(props);
        this.state = {

            showSearch: false,
            staffDetail: props.staffDetail,
            name: '',
            dataUp: props.staffDetail.staff_Hierarchy.upStaff,
            dataBelow: props.staffDetail.staff_Hierarchy.belowStaff,
            isEdit: props.isEdit === null ? false : props.isEdit,
            editAboutme: false,
            editContact: false,
            editProjects: false,
            editRoles: false,
            editSkills: false,
            editDeploymentStatus: false,
            txtAboutMe: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.aboutme,
            txtPreferredName: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.preferred_Name,
            txtPronoun: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.pronoun,
            txtAlternateNumber: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.alternate_Number == null ? '' : props.staffDetail.staffData.alternate_Number.replace('+44', ''),
            txtPADetails: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.padetails == null ? '' : props.staffDetail.staffData.padetails,
            query: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.padetails == null ? '' : props.staffDetail.staffData.padetails.split('|')[0],
            emailPAdetail: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.padetails == null ? '' : props.staffDetail.staffData.padetails.split('|')[1],
            txtProjects: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.projects,
            txtRoles: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.role_Responsibility,
            txtSkills: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.skills,
            txtDeploymentStatus: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.deployment_Status,
            txtCovidDeployed: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.covidDeployed,
            txtClinicalExp: props.staffDetail.staffData == null ? '' : {
                label: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.clinicalExp,
                value: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.clinicalExp
            },
            txtTypeOfClinical: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.typeOfClinical,
            txtReservist: props.staffDetail.staffData == null ? '' : {
                label: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.reservist,
                value: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.reservist
            },
            dbSkills: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.skills,
            dbRoles: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.role_Responsibility,
            dbProjects: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.projects,
            dbAboutMe: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.aboutme,
            dbPreferredName: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.preferred_Name,
            dbPronoun: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.pronoun,
            dbAlternateNumber: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.alternate_Number == null ? '' : props.staffDetail.staffData.alternate_Number.replace('+44', ''),
            dbPADetails: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.padetails == null ? '' : props.staffDetail.staffData.padetails.split('|')[0],
            dbPADetailsValue: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.padetails == null ? '' : props.staffDetail.staffData.padetails.split('|')[1],
            dbDeploymentStatus: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.deployment_Status,
            dbCovidDeployed: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.covidDeployed,
            dbClinicalExp: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.clinicalExp,
            dbTypeOfClinical: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.typeOfClinical,
            dbReservist: props.staffDetail.staffData == null ? '' : props.staffDetail.staffData.reservist,
            skillOptions: [],
            clinicalOptions: [],
            CovidDeployedOptions: [],
            deploymentStatusOptions: [],
            roleOptions: [],
            projectOptions: [],
            optionsDb: [],
            dbClinicalOptions: [],
            dbCovidDeployedOptions: [],
            inputValue: '',
            inputValueRole: '',
            inputValueProject: '',
            inputValueDeploymentStatus: '',
            inputValueClinicalType: '',
            inputValueCovidDeployed: '',
            imgAbout: editImg,
            imgContact: editImg,
            imgProject: editImg,
            imgSkill: editImg,
            imgDeploymentStatus: editImg,
            imgRole: editImg,
            saveImgAbout: saveImg,
            saveImgContact: saveImg,
            saveImgProject: saveImg,
            saveImgSkill: saveImg,
            saveImgDeploymentStatus: saveImg,
            saveImgRole: saveImg,
            deleteImgProject: deleteImg,
            deleteImgAbout: deleteImg,
            deleteImgContact: deleteImg,
            deleteImgSkill: deleteImg,
            deleteImgDeploymentStatus: deleteImg,
            deleteImgRole: deleteImg,
            visible: false,
            toggleDeploymentStatus: false,
            staffSearchData: [],
            masters: [],

            showCovid: false,
            showClinical: false,
            paNameClass: true,
            altNumberClass: true,
            startDate: props.staffDetail.staffData == null ? '' : Date.parse(props.staffDetail.staffData.startDate),
            endDate: props.staffDetail.staffData == null ? '' : Date.parse(props.staffDetail.staffData.endDate),

            dateFromClass: true,
            dateToClass: true,
            cursor: 0,
            site1: '',
            site: '',
            sites: [],
            CustomOption: [],
            searchField: null
        };

        this.loadSingleStaff = this.props.loadSingleStaff;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAlternateNumberInputChange = this.handleAlternateNumberInputChange.bind(this);
        this.handlePADetailInputChange = this.handlePADetailInputChange.bind(this);
        this.save = this.save.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeClinical = this.handleChangeClinical.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeProject = this.handleChangeProject.bind(this);
        this.handleChangeDeploymentStatus = this.handleChangeDeploymentStatus.bind(this);
        this.handleCovidDeployedChange = this.handleCovidDeployedChange.bind(this);
        this.handleClinicalExpChange = this.handleClinicalExpChange.bind(this);
        this.handleTypeOfClinicalChange = this.handleTypeOfClinicalChange.bind(this);
        this.handleReservistChange = this.handleReservistChange.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOverSave = this.handleMouseOverSave.bind(this);
        this.handleMouseOutSave = this.handleMouseOutSave.bind(this);
        this.handleMouseOverCancel = this.handleMouseOverCancel.bind(this);
        this.handleMouseOutCancel = this.handleMouseOutCancel.bind(this);
        this.handleClickvisible = this.handleClickvisible.bind(this);//up down button
        this.handleClickToggleDeploymentStatus = this.handleClickToggleDeploymentStatus.bind(this);
        this.inputSearchRefpaDetail = createRef();  // creating ref n
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSetTextboxValue = this.onSetTextboxValue.bind(this);
        this.handleViewDetail = this.handleViewDetail.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this); //Added by Apps
        this.handleSiteChange = this.handleSiteChange.bind(this); //Added by Apps
        this.handleOnChange = this.handleOnChange.bind(this); //Added by Apps
        (async () => fetch('api/Staff/GetStaffMaster')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    skillOptions: data.masterSkills, optionsDb: data.masterSkills,
                    masters: data, clinicalOptions: data.typeOfClinical,
                    dbClinicalOptions: data.typeOfClinical,
                    CovidDeployedOptions: data.covidDeployed,
                    dbCovidDeployedOptions: data.covidDeployed
                });
            }))();

    }
    //region 
    componentDidMount() {
        document.addEventListener("click", this.onClickOutsidePA);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.onClickOutsidePA);
    }
    componentDidUpdate() {
        if (this.state.cursor >= 0) {
            if (document.getElementById("paName" + this.state.cursor)) {
                document.getElementById("paName" + this.state.cursor).focus();
            }
        } else if (this.state.cursor == 0) {
            if (document.getElementById("query")) {
                document.getElementById("query").focus();
            }
        }
    }

    onClickOutsidePA = e => {
        const element = e.target;
        if (true) {
            this.setState({ ...this.state, showSearch: false });
            return null;
        } else {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ ...this.state, showSearch: true });
            return null;
        }
    };
    // Out click end
    _handleKeyDown = (e) => {
        this.setState({ ...this.state, cursor: -1 });
        if (e.keyCode === 40) {

            let { cursor, staffSearchData } = this.state;

            const { keyCode } = e;
            switch (keyCode) {
                case 38:
                    if (cursor > 0) {
                        this.setState({ ...this.state, cursor: cursor - 1 });
                    }
                    break;
                case 40:
                    if (cursor < staffSearchData.length - 1) {
                        this.setState({ ...this.state, cursor: cursor + 1 });
                    }
                    break;
                default:
            }
        } else {

        }
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({ showSearch: false });
        }
        let { cursor, staffSearchData } = this.state;

        const { keyCode } = e;
        switch (keyCode) {
            case 38:
                if (cursor > 0) {
                    this.setState({ ...this.state, cursor: cursor - 1 });
                } else {
                    this.setState({ ...this.state, cursor: staffSearchData.length - 1 });
                }
                break;
            case 40:
                if (cursor < staffSearchData.length - 1) {
                    this.setState({ ...this.state, cursor: cursor + 1 });
                } else {
                    this.setState({ ...this.state, cursor: 0 });
                }
                break;
            default:
        }
    }

    handleStartChange = date => {
        this.setState({
            startDate: date, endDate: ''
        });
    };

    handleEndChange = date => {
        this.setState({
            endDate: date
        });
    };

    handleClickvisible() {
        this.setState({ visible: !this.state.visible });
    }

    handleClickToggleDeploymentStatus(status) {

        this.setState({ toggleDeploymentStatus: status, emailPAdetail: this.state.emailPAdetail });
        if (this.state.txtDeploymentStatus == "Deployed") {
            this.setState({ showCovid: true });
        } else { this.setState({ showCovid: false }); }

        if (this.state.txtClinicalExp.value == "Yes" || this.state.txtClinicalExp == "Yes") {
            this.setState({ showClinical: true });
        } else { this.setState({ showClinical: false }); }

    }

    handleMouseOver = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: editHoverImg
        });
    }

    handleMouseOut = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: editImg
        });
    }
    //on Mouse hover on Save Icon
    handleMouseOverSave = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: saveHoverImg
        });
    }

    handleMouseOutSave = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: saveImg
        });
    }
    //on Mouse hover on Cancel Icon
    handleMouseOverCancel = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: deleteHoverImg
        });
    }

    handleMouseOutCancel = (event) => {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: deleteImg
        });
    }
    handleChange = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
            var optionsArr = [];
            if (actionMeta.action === 'select-option') {
                optionsArr = this.state.skillOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtSkills: list.substring(0, list.length - 1), skillOptions: optionsArr });
            }
            if (actionMeta.action === 'create-option') {
                optionsArr = this.state.skillOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtSkills: list.substring(0, list.length - 1), skillOptions: optionsArr });
            }
            if (actionMeta.action === 'remove-value') {
                optionsArr = this.state.optionsDb.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtSkills: list.substring(0, list.length - 1), skillOptions: optionsArr });
            }
            this.setState({ txtSkills: list.substring(0, list.length - 1) });
        }
        this.setState({ txtSkills: list.substring(0, list.length - 1) });
    };
    //For clinical type
    handleChangeClinical = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
            var optionsArr = [];
            if (actionMeta.action === 'select-option') {
                optionsArr = this.state.clinicalOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtTypeOfClinical: list.substring(0, list.length - 1), clinicalOptions: optionsArr });
            }
            if (actionMeta.action === 'create-option') {
                optionsArr = this.state.clinicalOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtTypeOfClinical: list.substring(0, list.length - 1), clinicalOptions: optionsArr });
            }
            if (actionMeta.action === 'remove-value') {
                optionsArr = this.state.dbClinicalOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtTypeOfClinical: list.substring(0, list.length - 1), clinicalOptions: optionsArr });
            }
            this.setState({ txtTypeOfClinical: list.substring(0, list.length - 1) });
        }
        this.setState({ txtTypeOfClinical: list.substring(0, list.length - 1) });
    };
    //For Covid deployed
    handleCovidDeployed = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
            var optionsArr = [];
            if (actionMeta.action === 'select-option') {
                optionsArr = this.state.CovidDeployedOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtCovidDeployed: list.substring(0, list.length - 1), CovidDeployedOptions: optionsArr });
            }
            if (actionMeta.action === 'create-option') {
                optionsArr = this.state.CovidDeployedOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtCovidDeployed: list.substring(0, list.length - 1), CovidDeployedOptions: optionsArr });
            }
            if (actionMeta.action === 'remove-value') {
                optionsArr = this.state.dbCovidDeployedOptions.filter(function (obj) { return value.indexOf(obj) == -1; });
                this.setState({ txtCovidDeployed: list.substring(0, list.length - 1), CovidDeployedOptions: optionsArr });
            }
            this.setState({ txtCovidDeployed: list.substring(0, list.length - 1) });
        }
        this.setState({ txtCovidDeployed: list.substring(0, list.length - 1) });
    };
    //for Roles on changes event
    handleChangeRole = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
        }
        this.setState({ txtRoles: list.substring(0, list.length - 1) });
    };
    //for Project on changes event
    handleChangeProject = (value, actionMeta) => {
        var list = '';
        if (value != null) {
            value.map(result => {
                if (result.label != undefined) {
                    return list += result.label + ',';
                }
            })
        }
        this.setState({ txtProjects: list.substring(0, list.length - 1) });
    };
    //for Deployment Status on changes event
    handleChangeDeploymentStatus = (v) => {
        this.setState({ txtDeploymentStatus: v.value });

        if (v.value == "Deployed") {
            this.setState({ showCovid: true, txtCovidDeployed: '', txtClinicalExp: '', txtTypeOfClinical: '', txtReservist: '', startDate: '', endDate: '' });
        } else { this.setState({ showCovid: false, txtCovidDeployed: '', txtClinicalExp: '', txtTypeOfClinical: '', txtReservist: '', startDate: '', endDate: '' }); }


    };
    handleCovidDeployedChange = (inputValueCovidDeployed) => {
        this.setState({ inputValueCovidDeployed });
    };
    handleClinicalExpChange = (v) => {
        this.setState({ txtClinicalExp: v });
        if (v.value == "Yes") {
            this.setState({ showClinical: true });
        } else { this.setState({ showClinical: false, txtTypeOfClinical: '' }); }
    };
    handleTypeOfClinicalChange = (inputValueClinicalType) => {
        this.setState({ inputValueClinicalType });
    };
    handleReservistChange = (v) => {
        this.setState({ txtReservist: v });
    };
    handleInputChangeSearch = (inputValue) => {
        this.setState({ inputValue });
    };
    handleInputChangeRole = (inputValueRole) => {
        this.setState({ inputValueRole });
    };
    handleInputChangeProject = (inputValueProject) => {
        this.setState({ inputValueProject });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            staffDetail: nextProps.staffDetail,
            dataUp: nextProps.staffDetail.staff_Hierarchy.upStaff,
            dataBelow: nextProps.staffDetail.staff_Hierarchy.belowStaff,
            isEdit: nextProps.isEdit == null ? false : nextProps.isEdit,
            txtAboutMe: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.aboutme,
            txtProjects: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.projects,
            txtRoles: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.role_Responsibility,
            txtSkills: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.skills,
            txtPreferredName: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.preferred_Name,
            txtPronoun: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.pronoun,
            //txtAlternateNumber: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.alternate_Number,
            txtAlternateNumber: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.alternate_Number == null ? '' : nextProps.staffDetail.staffData.alternate_Number.replace('+44', ''),
            txtPADetails: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.padetails == null ? '' : nextProps.staffDetail.staffData.padetails,
            //emailPAdetail: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.padetails == null ? '' : nextProps.staffDetail.staffData.padetails.split('|')[1],
            txtDeploymentStatus: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.deployment_Status == null ? '' : nextProps.staffDetail.staffData.deployment_Status,
            txtCovidDeployed: nextProps.staffDetail.staffData == null ? '' :nextProps.staffDetail.staffData.covidDeployed == null ? '' : nextProps.staffDetail.staffData.covidDeployed,
            txtClinicalExp: nextProps.staffDetail.staffData == null ? '' :nextProps.staffDetail.staffData.clinicalExp == null ? '' : nextProps.staffDetail.staffData.clinicalExp,
            txtTypeOfClinical: nextProps.staffDetail.staffData == null ? '' :nextProps.staffDetail.staffData.typeOfClinical == null ? '' : nextProps.staffDetail.staffData.typeOfClinical,
            txtReservist: nextProps.staffDetail.staffData == null ? '' :nextProps.staffDetail.staffData.reservist == null ? '' : nextProps.staffDetail.staffData.reservist,
            startDate: nextProps.staffDetail.staffData == null ? '' : nextProps.staffDetail.staffData.startDate == null ? '' : Date.parse(nextProps.staffDetail.staffData.startDate),
            endDate: nextProps.staffDetail.staffData == null ? '' :nextProps.staffDetail.staffData.endDate == null ? '' : Date.parse(nextProps.staffDetail.staffData.endDate),
        });
    }

    onSelect = (selectedList, selectedItem) => {
        var list = '';
        selectedList.map(result => {
            if (result.name !== undefined) {
                return list += result.name + ',';

            }
        })
        this.setState({ txtSkills: list.substring(0, list.length - 1) });
    }

    onRemove = (selectedList, removedItem) => {
        var list = '';
        selectedList.map(result => {
            if (result.name != undefined) {
                return list += result.name + ',';
            }
        })

        this.setState({ txtSkills: list.substring(0, list.length - 1) });
    }
    handleViewDetail = (email) => {
        //Session Management
        let obj = {
            login_addr: email
        }
        localStorage.setItem('emp', JSON.stringify(obj));
        //End Of Session Management

        this.props.history.push({ pathname: "/History" });
    }

    save() {
        this.setState({ imgAbout: editImg, imgContact: editImg, imgProject: editImg, imgRole: editImg, imgSkill: editImg, imgDeploymentStatus: editImg });//Set default edit img
        let st = this.state.staffDetail.staffData;
        if (st == null) {
            st = {};
            st.login_Address = this.state.staffDetail.login_Address;
            st.employee_Email_Address = this.state.staffDetail.employee_Email_Address;
        }
        st.aboutme = this.state.txtAboutMe === null ? '' : this.state.txtAboutMe;
        st.preferred_Name = this.state.txtPreferredName === null ? '' : this.state.txtPreferredName;
        st.pronoun = this.state.txtPronoun === null ? '' : this.state.txtPronoun;
       
        if (this.state.txtAlternateNumber!==null) {
            if (this.state.txtAlternateNumber.length !== 10 && this.state.txtAlternateNumber.length !== 0) {
                this.setState({ altNumberClass: false });
                if (this.state.emailPAdetail === "" && this.state.query !== "") {
                    this.setState({ paNameClass: false, cursor: 0 });
                    this.setState({ query: '' });
                }
                return;
            } else {
                this.setState({ altNumberClass: true });
            }
        }
       
        if (this.state.emailPAdetail === "" && this.state.query !== "") {
            this.setState({ paNameClass: false, cursor: 0 });
            this.setState({ query: '' });
            return;
        }
        this.setState({ cursor: 0 });
        st.alternate_Number = this.state.txtAlternateNumber === null ? '' : this.state.txtAlternateNumber;
        //st.padetails = this.state.site1 === '' ? '' : this.state.site1 + '|' + this.state.emailPAdetail;
        st.padetails = this.state.query === null ? '' : this.state.query + '|' + this.state.emailPAdetail;
        st.projects = this.state.txtProjects === null ? '' : this.state.txtProjects;
        st.role_Responsibility = this.state.txtRoles === null ? '' : this.state.txtRoles;
        st.skills = this.state.txtSkills === null ? '' : this.state.txtSkills;
        st.deployment_Status = this.state.txtDeploymentStatus === null ? '' : this.state.txtDeploymentStatus.value === undefined ? this.state.txtDeploymentStatus : this.state.txtDeploymentStatus.value;
        st.employee_Number = this.state.staffDetail.employee_Number;
        st.covidDeployed = this.state.txtCovidDeployed === null ? '' : this.state.txtCovidDeployed.value === undefined ? this.state.txtCovidDeployed : this.state.txtCovidDeployed.value;
        st.clinicalExp = this.state.txtClinicalExp === null ? '' : this.state.txtClinicalExp.value === undefined ? this.state.txtClinicalExp : this.state.txtClinicalExp.value;
        st.typeOfClinical = this.state.txtTypeOfClinical === null ? '' : this.state.txtTypeOfClinical;
        st.reservist = this.state.txtReservist === null ? '' : this.state.txtReservist.value === undefined ? this.state.txtReservist : this.state.txtReservist.value;
        st.startDate = this.state.startDate === null ? '' : new Date(moment(this.state.startDate).format("YYYY-MM-DD"));

        st.endDate = this.state.endDate === null ? '' : new Date(moment(this.state.endDate).format("YYYY-MM-DD"));
        try {
            fetch('api/Staff/Save', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'content-type': 'application/json',
                },
                body: JSON.stringify(st)
            }).then(
                this.setState({
                    editAboutme: false,
                    editContact: false,
                    editProjects: false,
                    editRoles: false,
                    editSkills: false,
                    editDeploymentStatus: false,
                    dbSkills: this.state.txtSkills,
                    dbRoles: this.state.txtRoles,
                    dbProjects: this.state.txtProjects,
                    dbAboutMe: this.state.txtAboutMe,
                    dbPreferredName: this.state.txtPreferredName,
                    dbAlternateNumber: this.state.txtAlternateNumber,
                    dbDeploymentStatus: this.state.txtDeploymentStatus,
                    dbPADetails: this.state.query,
                    dbCovidDeployed: this.state.txtCovidDeployed,
                    dbClinicalExp: this.state.txtClinicalExp,
                    dbTypeOfClinical: this.state.txtTypeOfClinical,
                    dbReservist: this.state.txtReservist,
                    dbPronoun: this.state.txtPronoun,
                    //dbPADetails: this.state.site1,
                })
            );
        } catch (e) {
            alert(e);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleAlternateNumberInputChange(event) {
        const re = /^[0-9]+$/;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (value === '' || re.test(value)) {
            this.setState({
                [name]: value
            });
        }
    }
    handlePADetailInputChange(event) {
        const re = /^[a-zA-Z]+(([ '][a-zA-Z])?[a-zA-Z]*)*$/g;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (value === '' || re.test(value)) {
            this.setState({
                [name]: value
            });
        }
    }


    renderStaffTable = (employee) => {
        const employeeRows = employee.map((metric, index) => { return this.getRows(metric, index) })
        return (
            <div className="">
                <ul className="myUL">
                    {employeeRows}
                </ul>
            </div>
        );
    }

    renderStaffTableBelow = (empbelow) => {
        const employeeRows1 = empbelow.map((metric, index) => { return this.getRows1(metric, index) })
        return (
            <div className="">
                <ul className="myULLow">
                    {employeeRows1}
                </ul>
            </div>
        );
    }
    onInputChange = (event) => {
        const query = event.target.value;
        const re = /^[A-Za-z ]+$/g;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (value === '' || re.test(value)) {
            if (!query && query.length < 3) {
                this.setState({ [name]: value, staffSearchData: [], showSearch: false });
            } else {
                this.setState({ [name]: value, showSearch: true }, () => { this.renderStaffTabletest(query); });
            }
            this.setState({ [name]: value, emailPAdetail: '' });
        }
    };

    renderStaffTabletest = (query) => {
        this.setState({ query: query, staffSearchData: [] });
        if (query.length > 3) {
            (async () => fetch('api/Staff/SearchBlur?name=' + query)
                .then(response => response.json())
                .then(data => {
                    this.setState({ staffSearchData: data })
                }))();
        }
    };
    //onSetTextboxValue = (name, email) => {

    //    this.setState({ site1: name, emailPAdetail: email, paNameClass: true, altNumberClass: true });    
    //};
    onSetTextboxValue = (name, email) => {

        this.setState({ query: name, emailPAdetail: email, paNameClass: true, altNumberClass: true });
    };
    handleSiteChange = (string, { action }) => {

        this.setState({ sites: [] });

        if (action === "input-change") {
            if (string.length > 3) {
                (async () => fetch('api/Staff/SearchBlur?name=' + string)
                    .then(response => response.json())
                    .then(data => {

                        this.setState({ sites: data, loadStaff: false, showSearch: true })
                    }))();

            } else {
                this.setState({ sites: [] })
            }
            this.setState({ searchField: string, paNameClass: false });
        } else if (action === "set-value") {
            this.setState({ paNameClass: true });
        }
    };
    handleOnChange = v => {

        this.setState({ site: v });
        //this.onSetTextboxValue(site.label, site.value)
        this.setState({ site1: v.label, emailPAdetail: v.value, paNameClass: true, altNumberClass: true });
        //this.setState({ sites: [] });

    };

    renderSearchResult = () => {
        const { staffSearchData, cursor } = this.state;
        if (Object.keys(staffSearchData).length && staffSearchData.length) {
            return (
                <div className="searchUL" className={this.state.showSearch == true ? "searchUL" : "searchUL hidden "}>
                    <ul id="myUL" className="myUL ui-menu">
                        <li className="searchTagPrompt">Are you looking for </li>
                        {
                            staffSearchData.map((result, index) => {
                                return (
                                    result.employee_Number !== null ?
                                        <a id={"paName" + (index)} tabIndex={index} href="#" className={cursor == index ? "ui-menu-item colorBlack" : "ui-menu-item colorBlack"}
                                            key={result.employee_Number}
                                            onKeyDown={this.handleKeyDown}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.onSetTextboxValue(result.employee_First_Name + ' ' + result.employee_Last_Name, result.employee_Email_Address)
                                            }}>
                                            <div className="ui-menu-item">
                                                <div className="ui-menu-item-wrapper">{result.employee_First_Name} {result.employee_Last_Name}{result.preferred_Name === null || result.preferred_Name === "" ? <span className="font-15">, {result.position_Title}</span> : <span>({result.preferred_Name}),<span className="font-15"> {result.position_Title}</span></span>}</div>
                                            </div>
                                        </a> : null
                                )
                            })

                        }
                        <div className="hor-line-box" ></div>
                    </ul>
                </div>
            );
        }
    };

    renderDeploymentStatusDesc = () => {
        return (
            <div id="DeploymentStatus" name="DeploymentStatus" className="deploymentStatus-lbl">
                <br />
                <dl>
                    <dt>&#x2022; <span className="bold">Deployed</span> </dt>
                    <dd>- If you are currently deployed to work in a Covid-19 cell or have returned to clinical practice.</dd>
                    <dt>&#x2022; <span className="bold">BAU</span></dt>
                    <dd>- If you are in your BAU role. BAU is Business As Usual, so this would be your day-to-day tasks and responsibilities that are non-Covid-19 cell related.</dd>
                    <dt>&#x2022; <span className="bold">BAU Critical</span></dt>
                    <dd>- If you are in your BAU role but supporting essential Covid-19 cell work.</dd>
                </dl>
            </div>
        );
    }

    getRows = (metricdtls, index) => {
        const firstRow = (
            <div key={metricdtls.employee_Number}>
                <div className={this.state.dataUp.length - 1 == index ? "row mar-left" : "row"} title={metricdtls.employee_Email_Address}>
                    <li>
                        <div className="img-border">
                            <div style={{ position: "relative", width: "3rem" }}>
                                <img alt="" src={(metricdtls.staffData === null || metricdtls.staffData.imgdata === null || metricdtls.staffData.imgdata === '' ? user : metricdtls.staffData.imgdata)} className="staffOrgIcon" />
                                <PresenceStatus isSmall="true" emailId={metricdtls.login_Address} />
                            </div>
                        </div>
                    </li>
                    <li className="liname"><a className="orgs-lbl" href="#" onClick={(e) => { this.props.loadSingleStaff(null, metricdtls.employee_Email_Address); e.preventDefault(); }}>{metricdtls.employee_First_Name} {metricdtls.employee_Last_Name}</a>
                        <div className="lipos">{metricdtls.position_Title}</div></li>
                </div>
                {this.state.dataUp.length - 1 === index && this.state.dataBelow.length > 0 ? <div className="row mar-left-30 direct-rpt">Direct Reports({this.state.dataBelow.length})
                <div onClick={this.handleClickvisible}>{this.state.visible ? <img alt="" src={minusImg} className="plusMinusIcon" /> : <img alt="" src={addImg} className="plusMinusIcon" />}</div> </div> : <div></div>}

                <div className={this.state.dataUp.length - 1 === index ? "mar-left" : "row vert-line"} ></div>
                <div className={this.state.dataUp.length - 2 === index ? "hor-line" : "row"} ></div>
            </div >
        )
        return firstRow;
    }
    getRows1 = (metricdtls, index) => {
        const firstRow = (
            <div key={index}>

                <div className={this.state.dataBelow.length - 1 === index ? "row mar-left-31" : "row mar-left-31"} title={metricdtls.employee_Email_Address}>
                    <li><div className="img-border">
                        <div style={{ position: "relative", width: "3rem" }}>
                            <img alt="" src={(metricdtls.staffData === null || metricdtls.staffData.imgdata === null || metricdtls.staffData.imgdata === '' ? user : metricdtls.staffData.imgdata)} className="staffOrgIcon" />
                            <PresenceStatus isSmall="true" emailId={metricdtls.login_Address} />
                        </div>
                    </div>
                    </li>
                    <li className="low-liname"><a className="orgs-lbl" href="#" onClick={(e) => { this.props.loadSingleStaff(null, metricdtls.employee_Email_Address); e.preventDefault(); }}>{metricdtls.employee_First_Name} {metricdtls.employee_Last_Name}</a>
                        <div className="lipos">{metricdtls.position_Title}</div></li>
                </div>
                <div className={this.state.dataBelow.length - 1 === index ? "" : ""} ></div>
            </div>
        )
        return firstRow;
    }


    render() {
        let contents = this.renderStaffTable(this.state.dataUp);
        let contentsBelow = this.renderStaffTableBelow(this.state.dataBelow);
        let showDeploymentStatusDesc = this.renderDeploymentStatusDesc();
        const { inputValue, inputValueRole, inputValueProject, masters, inputValueClinicalType, inputValueCovidDeployed } = this.state;
        const { query } = this.state;
        const defaultOptions = [
            { value: 'Deployed', label: 'Deployed' },
            { value: 'BAU', label: 'BAU' },
            { value: 'BAU Critical', label: 'BAU Critical' },
        ];

        let CustomOption = props => {
            const { data, innerRef, innerProps } = props;
            return (
                data.value !== 'all' ?
                    <components.Option {...props}>
                        <div className={props.data.value === 'all' ? 'custom-opt' : ''}>{props.data.label}
                            <span style={{ fontSize: 13 }}>{props.data.subLabel}</span>
                        </div>
                    </components.Option> : null
            );
        };
        return (
            <div className="body">
                <h1></h1>
                <div className="divstaffMain">
                    <Container fluid={true}>
                        <Row className="marginBottom10">
                            <Col md={9}>
                                <Row className="marginBottom10">
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader><CardImg alt="" src={aboutmeImg} className="cardMenuIcon img-fluid" /><span className="mar-left staffCardTitle title-lbl">About me</span>
                                                {this.state.editAboutme ?
                                                    <small className="float-sm-right" >
                                                        <CardImg alt="" title="Save" name="saveImgAbout" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgAbout} className="menuIcon" onClick={() => this.save()} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgAbout" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgAbout} className="menuIcon" onClick={() => this.setState({ editAboutme: !this.state.editAboutme, imgAbout: editImg, txtAboutMe: this.state.dbAboutMe, txtPreferredName: this.state.dbPreferredName, txtPronoun: this.state.dbPronoun })} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right" >
                                                        <CardImg alt="" title="Add/Edit Summary" name="imgAbout" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgAbout} className="menuIcon" onClick={() => this.setState({ editAboutme: !this.state.editAboutme, deleteImgAbout: deleteImg })} /></small>}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={3}>
                                                        <div style={{ position: "relative", width: "6rem" }}>
                                                            <CardImg alt="" style={{ display: "block" }} width="100%"
                                                                src={(this.state.staffDetail.staffData === null || this.state.staffDetail.staffData.imgdata === null || this.state.staffDetail.staffData.imgdata === ''
                                                                    ? user : this.state.staffDetail.staffData.imgdata)}
                                                                className="staffMainIcon" />
                                                            <PresenceStatus emailId={this.state.staffDetail.login_Address} isSmall={false} />
                                                        </div>
                                                    </Col>
                                                    <Col md={5}>
                                                        <CardText><b>Employee Name: </b>{this.state.staffDetail.employee_Individual_Title} {this.state.staffDetail.employee_First_Name}  {this.state.staffDetail.employee_Last_Name}</CardText>

                                                        {this.state.editAboutme ?
                                                            <CardText><b>Preferred Name: </b>
                                                                <Input style={{ width: "58%", display: "inline", height: "1.3rem", fontSize: "12px", backgroundColor: "#FFF" }} placeholder="Type Preferred Name" type="text" id="txtPreferredName" maxLength="255" name="txtPreferredName" value={this.state.txtPreferredName} onChange={this.handleInputChange} /></CardText>
                                                            :
                                                            this.state.txtPreferredName === '' ? '' :<CardText><b>Preferred Name: </b>{this.state.txtPreferredName}</CardText>
                                                        }
                                                        {this.state.editAboutme ?
                                                            <CardText><b>Pronoun(s): </b>
                                                                <Input style={{ width: "54%", display: "inline", height: "1.3rem", fontSize: "12px", backgroundColor: "#FFF" }} placeholder="e.g He/Him, She/Her, They/Them" title="Type your pronouns. e.g He/Him, She/Her, They/Them" type="text" id="txtPronoun" maxLength="255" name="txtPronoun" value={this.state.txtPronoun} onChange={this.handleInputChange} /></CardText>
                                                            :
                                                            this.state.txtPronoun===''?'':<CardText><b>Pronoun(s): </b>{this.state.txtPronoun}</CardText>
                                                        }
                                                        <CardText><b>Organisation : </b>{this.state.staffDetail.organization_Name == "NHS England" || this.state.staffDetail.organization_Name == "NHS Improvement" || this.state.staffDetail.organization_Name == "NHSX" ? "NHS England" : this.state.staffDetail.organization_Name}</CardText>
                                                        <CardText hidden ><b>Role : </b>{this.state.staffDetail.role}</CardText>
                                                        <CardText><b>Position Title : </b>{this.state.staffDetail.position_Title}</CardText>
                                                        <CardText><b>Directorate  : </b>{this.state.staffDetail.directoratE_REGION}</CardText>
                                                    </Col>
                                                    <Col md={4}>
                                                        {this.state.editAboutme ?
                                                            <Input maxLength="510" placeholder="Write few lines about yourself. e.g I am Developer." type="textarea" id="txtAboutMe" name="txtAboutMe" value={this.state.txtAboutMe} onChange={this.handleInputChange} /> :
                                                            <CardText className="staffAboutMe">{this.state.txtAboutMe}</CardText>
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="marginBottom10">
                                    <Col md={6}>
                                        <Card className="marginBottom10">
                                            <CardHeader>
                                                <CardImg alt="" src={contactImg} className="cardMenuIcon" /><span className="mar-left staffCardTitle title-lbl">Contact</span>
                                                {this.state.editContact ?
                                                    <small className="float-sm-right" >
                                                        <CardImg alt="" title="Save" name="saveImgContact" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgContact} className="menuIcon" onClick={() => this.save()} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgContact" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgContact} className="menuIcon" onClick={() => this.setState({ editContact: !this.state.editContact, imgContact: editImg, txtContact: this.state.dbContact, txtAlternateNumber: this.state.dbAlternateNumber, query: this.state.dbPADetails })} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right" >
                                                        <CardImg alt="" title="Add/Edit Alternate Number" name="imgContact" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgContact} className="menuIcon" onClick={() => this.setState({ editContact: !this.state.editContact, deleteImgContact: deleteImg, paNameClass: true, altNumberClass: true, site1: '', searchField: '', site: { label: this.state.dbPADetails }, emailPAdetail: this.state.dbPADetails })} /></small>}
                                            </CardHeader>
                                            <CardBody>
                                                <CardText><b>Email : </b><a className="dark-lbl" href={"mailto:" + this.state.staffDetail.employee_Email_Address}>{this.state.staffDetail.employee_Email_Address}</a></CardText>
                                                <CardText><b>Office 365 Email : </b><a className="dark-lbl" name="office365" href={"https://teams.microsoft.com/l/chat/0/0?users=" + this.state.staffDetail.login_Address} target="_blank" rel="noopener noreferrer">{this.state.staffDetail.login_Address}</a></CardText>
                                                <CardText><b>Corporate Phone : </b>{this.state.staffDetail.work_Mobile}</CardText>
                                                {this.state.editContact ?
                                                    <CardText><b>Alternate Phone : +44 </b>
                                                        <Input
                                                            placeholder="Enter Alternate Phone" type="text" id="txtAlternateNumber" maxLength="10"
                                                            name="txtAlternateNumber" value={this.state.txtAlternateNumber}
                                                            onChange={this.handleAlternateNumberInputChange}
                                                            className={this.state.altNumberClass ? "paName " : "paName highlight"}
                                                        />
                                                    </CardText>
                                                    :
                                                    this.state.txtAlternateNumber === null || this.state.txtAlternateNumber === '' ?
                                                        ''
                                                        :
                                                        <CardText><b>Alternate Phone : </b> +44{this.state.txtAlternateNumber}</CardText>
                                                }
                                                <CardText><b>Line Manager Name : </b><a className="dark-lbl" title="View Information" href="#" onClick={(e) => { this.props.loadSingleStaff(null, this.state.staffDetail.supervisor_Email_Address); e.preventDefault(); }}>
                                                    {this.state.staffDetail.supervisor_Name}</a></CardText>
                                                <CardText><b>Line Manager Email : </b>{this.state.staffDetail.supervisor_Email_Address}</CardText>
                                                {this.state.editContact ?
                                                    <CardText>
                                                        <b>Personal Assistant (PA) :</b>
                                                        <Input style={{ width: "45%", display: "inline", height: "1.3rem", fontSize: "12px", backgroundColor: "#FFF" }}
                                                            placeholder="Enter PA Name"
                                                            type="text"
                                                            id="query" maxLength="100"
                                                            name="query"
                                                            value={query}
                                                            onChange={this.onInputChange}
                                                            ref={this.inputSearchRefpaDetail}
                                                            onKeyDown={this._handleKeyDown}
                                                            className={this.state.paNameClass ? "paName " : "paName highlight"}

                                                        />
                                                        <div className=""> {this.renderSearchResult()}</div>

                                                    </CardText>
                                                    :
                                                    (!this.state.isEdit) ?
                                                        this.state.txtPADetails===''?'':
                                                        <CardText><b>Personal Assistant (PA) : </b><a className="dark-lbl" title="View Information"
                                                            href="#"
                                                            onClick={(e) => {
                                                                this.props.loadSingleStaff(null, this.state.txtPADetails.split('|')[1]);
                                                                e.preventDefault();
                                                            }}>
                                                            {this.state.txtPADetails.split('|')[0]}</a>
                                                        </CardText>
                                                        :
                                                        <CardText><b>Personal Assistant (PA) : </b><a className="dark-lbl" title="View Information"
                                                            href="#" onClick={(e) => { this.props.loadSingleStaff(null, this.state.staffDetail.staffData.padetails.split('|')[1]); e.preventDefault(); }}>
                                                            {this.state.query}</a>
                                                        </CardText>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card>
                                            <CardHeader><CardImg alt="" src={projectImg} className="cardMenuIcon" /><span className="mar-left staffCardTitle title-lbl">Key works and projects</span>
                                                {this.state.editProjects ?
                                                    <small className="float-sm-right">
                                                        <CardImg alt="" title="Save" name="saveImgProject" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgProject} className="menuIcon" onClick={() => this.save()} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgProject" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgProject} className="menuIcon" onClick={() => this.setState({ editProjects: !this.state.editProjects, txtProjects: this.state.dbProjects, imgProject: editImg })} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right">
                                                        <CardImg alt="" title="Add/Edit Projects" name="imgProject" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgProject} className="menuIcon" onClick={() => this.setState({ editProjects: !this.state.editProjects, deleteImgProject: deleteImg })} /></small>}
                                                <table className="section">
                                                    <tr>
                                                        <td>
                                                            {this.state.isEdit && <p className="guidance-lbl">Please note any project areas that you are involved in e.g. developing new ways of work, HR Digitisation</p>}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CardHeader>

                                            <CardBody>
                                                {this.state.editProjects ?
                                                    <div>
                                                        <CreatableSelect
                                                            id="Projects"
                                                            components={{ DropdownIndicator: () => null }}
                                                            isMulti
                                                            onChange={this.handleChangeProject}
                                                            options={this.state.projectOptions}
                                                            createOptionPosition={"first"}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={true}
                                                            formatCreateLabel={() => inputValueProject}
                                                            onInputChange={this.handleInputChangeProject}
                                                            placeholder="Type your key works and projects and press enter..."
                                                            maxLength={510}
                                                            isClearable={false}
                                                            value={(this.state.txtProjects === "" ? "" : this.state.txtProjects.split(",").map(function (label, value) {
                                                                return { label: label, value: label };
                                                            }))}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.state.txtProjects.split(",").map(function (element, index) {
                                                            return <CardText key={index} id={index}>{element}</CardText>;
                                                        }
                                                        )}

                                                    </div>}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="marginBottom10">
                                    <Col md={6}>
                                        <Card className="marginBottom10">
                                            <CardHeader><CardImg alt="" src={rolesImg} className="cardMenuIcon" /><span className="mar-left staffCardTitle title-lbl">Extra Roles & Responsibilities </span>
                                                {this.state.editRoles ?
                                                    <small className="float-sm-right">
                                                        <CardImg alt="" title="Save" name="saveImgRole" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgRole} className="menuIcon" onClick={() => this.save()} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgRole" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgRole} className="menuIcon" onClick={() => this.setState({ editRoles: !this.state.editRoles, txtRoles: this.state.dbRoles, imgRole: editImg })} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right">
                                                        <CardImg alt="" title="Add/Edit Roles & Responsibilities" name="imgRole" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgRole} className="menuIcon" onClick={() => this.setState({ editRoles: !this.state.editRoles, deleteImgRole: deleteImg })} /></small>}
                                                <table className="section">
                                                    <tr>
                                                        <td>
                                                            {this.state.isEdit && <p className="guidance-lbl">Please note any additional roles you are undertaking above and beyond your normal responsibility e.g. Coordination of COVID IT Systems Training </p>}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CardHeader>
                                            <CardBody>
                                                {this.state.editRoles ?
                                                    <div>
                                                        <CreatableSelect
                                                            id="Roles"
                                                            components={{ DropdownIndicator: () => null }}
                                                            isMulti
                                                            onChange={this.handleChangeRole}
                                                            options={this.state.roleOptions}
                                                            createOptionPosition={"first"}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={true}
                                                            formatCreateLabel={() => inputValueRole}
                                                            onInputChange={this.handleInputChangeRole}
                                                            placeholder="Type your roles and responsibilities and press enter..."
                                                            maxLength={510}
                                                            isClearable={false}
                                                            value={(this.state.txtRoles === "" ? "" : this.state.txtRoles.split(",").map(function (label, value) {
                                                                return { label: label, value: label };
                                                            }))}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        {this.state.txtRoles.split(",").map(function (element, index) {
                                                            return <CardText key={index} id={index}>{element}</CardText>;
                                                        }
                                                        )}
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        <Card>
                                            <CardHeader><CardImg alt="" src={skillImg} className="cardMenuIcon" /><span className="mar-left staffCardTitle title-lbl">Skills</span>
                                                {this.state.editSkills ?
                                                    <small className="float-sm-right">
                                                        <CardImg alt="" title="Save" name="saveImgSkill" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgSkill} className="menuIcon" onClick={() => this.save()} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgSkill" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgSkill} className="menuIcon" onClick={() => this.setState({ editSkills: !this.state.editSkills, txtSkills: this.state.dbSkills, imgSkill: editImg })} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right">
                                                        <CardImg alt="" title="Add/Edit Skills" name="imgSkill" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgSkill} className="menuIcon" onClick={() => this.setState({ editSkills: !this.state.editSkills, deleteImgSkill: deleteImg })} /></small>}
                                                <table className="section">
                                                    <tr>
                                                        <td>
                                                            {this.state.isEdit && <p className="guidance-lbl">Please note any additional skills you may/may not  use in your current role e.g. marketing, project management</p>}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CardHeader>

                                            <CardBody>
                                                {this.state.editSkills ?
                                                    <div>
                                                        <CreatableSelect
                                                            id="Skills"
                                                            components={{ DropdownIndicator: () => null }}
                                                            isMulti
                                                            onChange={this.handleChange}
                                                            options={this.state.skillOptions}
                                                            createOptionPosition={"first"}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={true}
                                                            formatCreateLabel={() => inputValue}
                                                            onInputChange={this.handleInputChangeSearch}
                                                            placeholder="Type your skills and press enter..."
                                                            maxLength={510}
                                                            isClearable={false}
                                                            value={(this.state.txtSkills === "" ? "" : this.state.txtSkills.split(",").map(function (label, value) {
                                                                return { label: label, value: label };
                                                            }))} />

                                                    </div> :
                                                    <div>
                                                        {this.state.txtSkills.split(",").map(function (element, index) {
                                                            return <span key={index} id={index} className={element === "" ? "" : "skills"}>{element}</span>;
                                                        })}
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="marginBottom10">
                                    <Col md={6}>
                                        <Card className="marginBottom10">
                                            <CardHeader><CardImg alt="" src={deploymentStatusImg} className="cardMenuIcon" />
                                                <span className="mar-left staffCardTitle title-lbl">Deployment Status</span>
                                                <a rel="noopener noreferrer" href="/History" target="_self" onClick={() => this.handleViewDetail(this.state.staffDetail.login_Address)}
                                                    className="mar-left staffCardTitle title-lbl" ><CardImg alt="" src={historyImg} className="cardMenuIcon mar-left1" /></a>
                                                {this.state.editDeploymentStatus ?
                                                    <small className="float-sm-right">
                                                        <CardImg alt="" title="Save" name="saveImgDeploymentStatus" onMouseOver={this.handleMouseOverSave} onMouseOut={this.handleMouseOutSave} src={this.state.saveImgDeploymentStatus} className="menuIcon" onClick={() => { this.save(); this.handleClickToggleDeploymentStatus(false) }} />
                                                        <span className="spanIconSpacing">&nbsp;</span>
                                                        <CardImg alt="" title="Cancel" name="deleteImgDeploymentStatus" onMouseOver={this.handleMouseOverCancel} onMouseOut={this.handleMouseOutCancel} src={this.state.deleteImgDeploymentStatus} className="menuIcon" onClick={() => { this.setState({ editDeploymentStatus: !this.state.editDeploymentStatus, txtDeploymentStatus: this.state.dbDeploymentStatus === null ? '' : this.state.dbDeploymentStatus, txtCovidDeployed: this.state.dbCovidDeployed === null || this.state.dbCovidDeployed === '' ? '' : this.state.dbCovidDeployed, txtClinicalExp: this.state.dbClinicalExp === null || this.state.dbClinicalExp === '' ? '' : this.state.dbClinicalExp, txtTypeOfClinical: this.state.dbTypeOfClinical === null ? '' : this.state.dbTypeOfClinical, txtReservist: this.state.dbReservist === null || this.state.dbReservist === '' ? '' : this.state.dbReservist, imgDeploymentStatus: editImg }); this.handleClickToggleDeploymentStatus(false) }} />
                                                    </small> :
                                                    this.state.isEdit && <small className="float-sm-right">
                                                        <CardImg alt="" title="Add/Edit Deployment Status" name="imgDeploymentStatus" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src={this.state.imgDeploymentStatus} className="menuIcon" onClick={() => { this.setState({ editDeploymentStatus: !this.state.editDeploymentStatus, deleteImgDeploymentStatus: deleteImg }); this.handleClickToggleDeploymentStatus(true) }} /></small>}
                                            </CardHeader>
                                            <CardBody>
                                                {this.state.toggleDeploymentStatus ?
                                                    <div>{showDeploymentStatusDesc}</div> : ""
                                                }

                                                {this.state.editDeploymentStatus ?
                                                    <div>
                                                        <p className="mar-top bold">Select the deployment status :</p>
                                                        <Select
                                                            id='DeploymentStatus'
                                                            value={defaultOptions.find(obj => obj.value === this.state.txtDeploymentStatus)}
                                                            onChange={this.handleChangeDeploymentStatus}
                                                            options={defaultOptions}
                                                            noOptionsMessage={() => 'No data found in search'}
                                                        />
                                                        {this.state.showCovid && <div>
                                                            <p className="mar-top bold"> Deployed Start Date :</p>
                                                            <Datetime dateFormat="dd-MM-yyyy" timeFormat={false}
                                                                selected={this.state.startDate} onChange={this.handleStartChange}
                                                                className={this.state.dateFromClass ? "form-control inputField" : "form-control inputField highlight"}
                                                                maxDate={this.state.endDate == '' ? null : this.state.endDate}
                                                                id='startDate'
                                                            />

                                                            <p className="mar-top bold">Deployed End Date:</p>


                                                            <Datetime dateFormat="dd-MM-yyyy"
                                                                timeFormat={false} selected={this.state.endDate}
                                                                onChange={this.handleEndChange} className={this.state.dateToClass ? "form-control inputField" : "form-control inputField highlight"}
                                                                minDate={this.state.startDate == '' ? null : this.state.startDate}
                                                                id='endDate'
                                                            />

                                                        </div>
                                                        }
                                                        {this.state.showCovid && <div>
                                                            <p className="mar-top bold">What COVID'19 cell are you deployed?</p>
                                                            <CreatableSelect
                                                                id="CovidDeployed"
                                                                components={{ DropdownIndicator: () => null }}
                                                                isMulti
                                                                onChange={this.handleCovidDeployed}
                                                                options={this.state.CovidDeployedOptions}
                                                                createOptionPosition={"first"}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={true}
                                                                formatCreateLabel={() => inputValueCovidDeployed}
                                                                onCreateOption={() => 'No Option'}
                                                                noOptionsMessage={() => 'No data found in search'}
                                                                onInputChange={this.handleCovidDeployedChange}
                                                                placeholder="Select COVID'19 cell and press enter..."
                                                                maxLength={510}
                                                                isClearable={false}
                                                                value={(this.state.txtCovidDeployed === "" || this.state.txtCovidDeployed === null ? ""
                                                                    : this.state.txtCovidDeployed.split(",").map(function (label, value) {
                                                                        return { label: label, value: label };
                                                                    }))} />
                                                        </div>
                                                        }

                                                        <p className="mar-top bold">Do you have clinical experience?</p>
                                                        <Select
                                                            id='ClinicalExpYN'
                                                            value={this.state.txtClinicalExp}
                                                            onChange={this.handleClinicalExpChange}
                                                            options={this.state.masters.clinicalExp}
                                                            noOptionsMessage={() => 'No data found in search'}
                                                        />
                                                        {this.state.showClinical && <div>
                                                            <p className="mar-top bold">What type of clinical experience do you have?</p>
                                                            <CreatableSelect
                                                                id="clinicalExp"
                                                                components={{ DropdownIndicator: () => null }}
                                                                isMulti
                                                                onChange={this.handleChangeClinical}
                                                                options={this.state.clinicalOptions}
                                                                createOptionPosition={"first"}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={true}
                                                                formatCreateLabel={() => inputValueClinicalType}
                                                                onCreateOption={() => 'No Option'}
                                                                noOptionsMessage={() => 'No data found in search'}
                                                                onInputChange={this.handleTypeOfClinicalChange}
                                                                placeholder="Select Clinical Type Experience and press enter..."
                                                                maxLength={510}
                                                                isClearable={false}
                                                                value={(this.state.txtTypeOfClinical === "" || this.state.txtTypeOfClinical === null ? ""
                                                                    : this.state.txtTypeOfClinical.split(",").map(function (label, value) {
                                                                        return { label: label, value: label };
                                                                    }))} />
                                                        </div>
                                                        }
                                                        <p className="mar-top bold">Are you a trained EPRR Reservist?</p>
                                                        <Select
                                                            id="Reservist"
                                                            value={this.state.txtReservist}
                                                            onChange={this.handleReservistChange}
                                                            options={this.state.masters.clinicalExp}
                                                            noOptionsMessage={() => 'No data found in search'}
                                                        />
                                                        <button id='submitButton' className="submitButton float" type="button" onClick={() => { this.save(); this.handleClickToggleDeploymentStatus(false) }}>Save</button>
                                                    </div> :
                                                    <div>
                                                        {(this.state.txtDeploymentStatus !== '' && this.state.txtDeploymentStatus !== null) ?
                                                            <CardText>
                                                                <b>Deployment Status : </b>
                                                                {this.state.txtDeploymentStatus === null ? '' :
                                                                    this.state.txtDeploymentStatus.value === undefined ?
                                                                        this.state.txtDeploymentStatus : this.state.txtDeploymentStatus.value}

                                                            </CardText>
                                                            : ''
                                                        }
                                                        {this.state.startDate === null ? (this.state.startDate !== null) : (this.state.startDate.toString() !== 'NaN') && this.state.startDate !== '' ?
                                                            <CardText><b>Deployed Start Date : </b>
                                                                {this.state.startDate === null ? '' :
                                                                    this.state.startDate === undefined ?
                                                                        this.state.startDate : moment(this.state.startDate).format("DD-MM-YYYY")}
                                                            </CardText>
                                                            : ''
                                                        }
                                                        {this.state.endDate === null ? (this.state.endDate !== null) : (this.state.endDate.toString() !== 'NaN') && this.state.endDate !== '' ?
                                                            <CardText><b>Deployed End Date : </b>
                                                                {this.state.endDate === null ? '' :
                                                                    this.state.endDate === undefined ?
                                                                        this.state.endDate : moment(this.state.endDate).format("DD-MM-YYYY")}
                                                            </CardText>
                                                            : ''
                                                        }

                                                        {this.state.txtCovidDeployed !== null && this.state.txtCovidDeployed !== '' ?
                                                            <CardText><b>COVID'19 cell : </b>
                                                                {this.state.txtCovidDeployed === null ? '' :
                                                                    this.state.txtCovidDeployed === undefined ?
                                                                        this.state.txtCovidDeployed.replaceAll(',', ', ') : this.state.txtCovidDeployed.replaceAll(',', ', ')}
                                                            </CardText>
                                                            : ''
                                                        }

                                                        {(this.state.txtClinicalExp.value !== null && this.state.txtClinicalExp !== null) && this.state.txtClinicalExp !== '' ?
                                                            <CardText><b>Clinical experience : </b>
                                                                {this.state.txtClinicalExp === null ? '' :
                                                                    this.state.txtClinicalExp.value === undefined ?
                                                                        this.state.txtClinicalExp : this.state.txtClinicalExp.value}
                                                            </CardText>
                                                            : ''
                                                        }
                                                        {this.state.txtTypeOfClinical !== null && this.state.txtTypeOfClinical !== '' ?
                                                            <CardText><b>Type of clinical experience : </b>
                                                                {this.state.txtTypeOfClinical === null ? '' :
                                                                    this.state.txtTypeOfClinical === undefined ?
                                                                        this.state.txtTypeOfClinical.replaceAll(',', ', ') : this.state.txtTypeOfClinical.replaceAll(',', ', ')}
                                                            </CardText>
                                                            : ''
                                                        }

                                                        {(this.state.txtReservist.value !== null && this.state.txtReservist !== null) && this.state.txtReservist !== '' ?
                                                            <CardText><b>Trained EPRR Reservist : </b>
                                                                {this.state.txtReservist === null ? '' :
                                                                    this.state.txtReservist.value === undefined ?
                                                                        this.state.txtReservist : this.state.txtReservist.value}
                                                            </CardText>
                                                            : ''
                                                        }
                                                    </div>
                                                }

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <CardHeader><CardImg alt="" src={orgImg} className="cardMenuIcon" /><span className="mar-left staffCardTitle title-lbl">Organisation</span></CardHeader>
                                    <CardBody>
                                        <div>{contents}</div>
                                        {this.state.visible ? <div>{contentsBelow}</div> : ""}

                                    </CardBody>
                                </Card>
                                <div className="esr-div">
                                    <p>In case of any issue with your data, please check your ESR records below.</p>
                                </div>
                                <div className="esr-div">
                                    <a href="https://my.esr.nhs.uk/" target="_blank" rel="noopener" aria-label="esr">
                                        <img alt="" src={esrImg} className="staffEsrIcon" />
                                    </a>
                                </div>

                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>)
    }
}